const BASE_URL = 'https://app.core-e2e.meliopayments.com';

// eslint-disable-next-line import/no-default-export
export default {
  server: {
    baseUrl: '',
  },
  web: {
    baseUrl: BASE_URL,
  },
  platformApp: {
    baseUrl: 'https://partnerships.alpha.melioservices.com/melio',
  },
  analytics: {
    shouldTrackEvents: true,
    shouldPrintEvents: true,
    shouldIdentify: true,
  },
  services: {
    intuit: {
      clientId: 'Q0gqJI4IaP8fEpNFaZeDOuw0LBiN7dPuUnbm5AZVYiNtb39Prz',
    },
    segment: {
      key: 'ktaEnCSilTDj4NuZ1Ny6rINi0rnZsAK4',
      shouldLoad: true,
    },
    intercom: {
      app_id: 'a44mis4s',
      isShow: false,
    },
    featureFlagProvider: {
      clientId: '608a6c9ebedae00c6063d332',
    },
  },
  quickbooks: {
    baseUrl: 'https://app.sandbox.qbo.intuit.com',
  },
  googleOptimize: {},
  meliome: {
    baseUrl: `${BASE_URL}/meliome/pay/`,
  },
  debounceDelay: 10,
  featureFlags: {
    allowMarkAsPaidQbDashboard: true,
  },
  sessionExpired: {
    timeout: 1000 * 20,
  },
};
