import { PureComponent } from 'react';
import { authApi } from 'src/modules/auth/api';
import { filesApi } from 'src/modules/files/api';
import { analytics } from 'src/services/analytics';
import guestApi from 'src/services/api/guests';
import { CreditCardFeePayment, DeliveryType } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { CouponType, FieldType, NavigateType, PaymentRequestOverrides, PaymentRequestType } from 'src/utils/types';
import { uuid } from 'src/utils/uuid';

type PaymentRequestStatus = {
  scheduled?: Date;
  failed?: Date;
  delivered?: Date;
  processed?: Date;
};

export type LocalFileProps = {
  fileName?: string;
  file?: File;
  error?: string;
};

type Props = {
  locationState: Record<string, any>;
  navigate: NavigateType;
  link: string;
  hash?: string;
  query: {
    totalAmount?: string;
    invoiceNumber?: string;
    /* eslint-disable camelcase */
    utm_content?: string;
  };
};

type State = {
  companyName: string;
  contactName: string;
  logoUrl: string;
  totalAmount: string;
  invoiceNumber: string;
  invoiceFile?: LocalFileProps;
  businessName: string;
  email: string;
  deliveryDate: Date | null;
  maxDeliveryDate: Date | null;
  minScheduledDate: Date;
  scheduledDate: Date | null;
  promotionName: string;
  memo: string;
  goodsReceived: string | null;
  selectedFundingSourceId: number;
  vendorId: number;
  deliveryMethodId: number;
  deliveryMethodType: DeliveryType;
  billId: number;
  showMelioMeCard: boolean;
  isLoading: boolean;
  paymentRequest: PaymentRequestType;
  fileStorageUrl?: string;
  filePreviewUrls?: string[];
  overrides?: PaymentRequestOverrides;
  paymentRequestStatus: PaymentRequestStatus;
  blockCCMoneyIn: boolean;
  blockDebitCardMoneyIn: boolean;
  isPayorGuest: boolean;
  approvalDecisionStatus: string;
  intuitAcctNum: string;
  utmContent?: string;
  ownedById: number | null;
  coupons: CouponType[] | null;
  isVendorAbsorbedFee: boolean;
  isAccountingOrg: boolean;
  payBillFlowUUID: string;
};

export const GUEST_ANALYTICS_TAG = 'GuestPaymentData';

export type GuestDataProps = {
  link: string;
  onChange: ({ id: string, value: any }) => void;
  onInvoiceFileChange: (file?: File) => LocalFileProps;
  onInvoiceFileClear(): void;
  navigateToGuestPage: (
    url: string,
    data?: Record<string, any>,
    exitIframe?: boolean,
    shouldReplaceCurrent?: boolean
  ) => void;
  prevRoute: string;
  nextRoute: string;
} & State;

// It's important to have withNavigator/withPreservedStateNavigate hocs before this hooks
export function withGuestData() {
  return function (Component: any) {
    return class ComponentWithGuestData extends PureComponent<Props, State> {
      constructor(props: Props) {
        super(props);

        const { locationState } = this.props;
        // We need to parse query params for the case when user is redirected from intuit login to funding sources page
        const {
          totalAmount: queryTotalAmount,
          invoiceNumber: queryInvoiceNumber,
          utm_content: queryUtmContent,
        } = this.props.query;

        const totalAmount = locationState?.totalAmount ? locationState.totalAmount : queryTotalAmount || '';
        const invoiceNumber = locationState?.invoiceNumber ? locationState.invoiceNumber : queryInvoiceNumber || '';
        const utmContent = locationState?.utmContent ? locationState?.utmContent : queryUtmContent;
        this.state = {
          companyName: locationState && (locationState.companyName || ''),
          contactName: locationState && (locationState.contactName || ''),
          logoUrl: locationState && (locationState.logoUrl || ''),
          totalAmount: convertCurrencyToNumber(totalAmount),
          invoiceNumber,
          invoiceFile: locationState?.invoiceFile,
          businessName: locationState && (locationState.businessName || ''),
          email: locationState && (locationState.email || ''),
          minScheduledDate:
            locationState && locationState.minScheduledDate ? new Date(locationState.minScheduledDate) : new Date(),
          deliveryDate: locationState && locationState.deliveryDate ? new Date(locationState.deliveryDate) : null,
          maxDeliveryDate:
            locationState && locationState.maxDeliveryDate ? new Date(locationState.maxDeliveryDate) : null,
          scheduledDate: locationState && locationState.scheduledDate ? new Date(locationState.scheduledDate) : null,
          memo: locationState && (locationState.memo || null),
          goodsReceived: locationState && (locationState.goodsReceived || null),
          selectedFundingSourceId: locationState && (locationState.selectedFundingSourceId || 0),
          vendorId: locationState && (locationState.vendorId || 0),
          deliveryMethodId: locationState && (locationState.deliveryMethodId || 0),
          deliveryMethodType: locationState && (locationState.deliveryMethodType || DeliveryType.ACH),
          blockCCMoneyIn: locationState?.blockCCMoneyIn || false,
          blockDebitCardMoneyIn: locationState?.blockDebitCardMoneyIn || false,
          billId: locationState && (locationState.billId || null),
          paymentRequest: locationState && (locationState.paymentRequest || {}),
          paymentRequestStatus: {},
          showMelioMeCard: true,
          isLoading: false,
          promotionName: locationState && (locationState.promotionName || null),
          filePreviewUrls: locationState?.filePreviewUrls,
          fileStorageUrl: locationState?.fileStorageUrl,
          overrides: locationState?.overrides,
          isPayorGuest: true,
          approvalDecisionStatus: locationState && (locationState.approvalDecisionStatus || ''),
          intuitAcctNum: '',
          utmContent,
          ownedById: locationState?.ownedById || null,
          coupons: locationState && (locationState.coupons || null),
          isVendorAbsorbedFee: false,
          isAccountingOrg: locationState && (locationState.isAccountingOrg || false),
          payBillFlowUUID: uuid(),
        };
      }

      getInitialState = async () => {
        const { link, hash } = this.props;
        this.setState({ isLoading: true });
        analytics.page('payor', 'start');
        try {
          const [
            {
              companyName,
              contactName,
              id,
              deliveryMethodId,
              deliveryMethodType,
              blockCCMoneyIn,
              blockDebitCardMoneyIn,
              promotionName,
              intuitAcctNum,
              ownedById,
              isVendorAbsorbedFee,
            },
            { showMelioMe },
          ] = await Promise.all([guestApi.getPublicVendorInfo(link), authApi.showMelioMeCard(link, hash)]);
          this.loadVendorLogo();

          let updatedState: Partial<State> = {
            vendorId: id,
            companyName,
            contactName,
            deliveryMethodId,
            deliveryMethodType,
            blockCCMoneyIn,
            blockDebitCardMoneyIn,
            promotionName,
            intuitAcctNum,
            showMelioMeCard: showMelioMe,
            isLoading: false,
            ownedById,
            isVendorAbsorbedFee,
          };

          if (hash) {
            const [
              { paymentRequest, coupons, fileStorageUrl, filePreviewUrls, overrides, showRegisterCTA },
              { trackingStatus },
            ] = await Promise.all([
              guestApi.getPaymentRequest(link, hash),
              guestApi.getPaymentRequestStatus(link, hash),
            ]);
            const isVendorAbsorbFeeInPaymentRequest = paymentRequest?.feesPaidBy === CreditCardFeePayment.VENDOR;
            updatedState = {
              ...updatedState,
              paymentRequest,
              paymentRequestStatus: trackingStatus,
              totalAmount: paymentRequest.totalAmount,
              invoiceNumber: paymentRequest.invoiceNumber,
              fileStorageUrl,
              filePreviewUrls,
              overrides,
              email: paymentRequest.customerEmail,
              businessName: paymentRequest.customerName,
              isPayorGuest: Boolean(showRegisterCTA),
              promotionName,
              coupons,
              isVendorAbsorbedFee: isVendorAbsorbFeeInPaymentRequest || isVendorAbsorbedFee,
            };
          }

          this.setState((state) => ({ ...state, ...updatedState }));
        } catch (e) {
          this.setState({
            showMelioMeCard: false,
            isLoading: false,
          });
        }
      };

      componentDidMount() {
        this.updateAnalyticsExtraProperties();
        this.getInitialState();
      }

      componentDidUpdate() {
        this.updateAnalyticsExtraProperties();
      }

      updateAnalyticsExtraProperties = () => {
        const { link, hash } = this.props;
        const {
          vendorId,
          paymentRequest,
          blockCCMoneyIn,
          blockDebitCardMoneyIn,
          isPayorGuest,
          isVendorAbsorbedFee,
        } = this.state;
        const guestPaymentProps = {
          ...analytics.getExtraProperties(),
          ownedVendorId: vendorId || null,
          paymentRequestId: paymentRequest?.id,
          blockCCMoneyIn,
          blockDebitCardMoneyIn,
          isVendorAbsorbedFee,
          isPayorGuest,
          handle: link,
          hash,
        };
        analytics.setExtraProperties(GUEST_ANALYTICS_TAG, guestPaymentProps);
      };

      onChange = ({ id, value }: FieldType) => {
        this.setState((prevState) => ({
          ...prevState,
          [id]: value,
        }));
      };

      onInvoiceFileChange = (file?: File) => {
        const updatedInvoiceFile = { fileName: file?.name, file };
        analytics.track('payor', 'upload-invoice-file', {
          action: 'file-selected',
          vendorId: this.state.vendorId,
        });
        this.setState((prevState) => ({
          ...prevState,
          invoiceFile: updatedInvoiceFile,
        }));

        return updatedInvoiceFile;
      };

      onInvoiceFileClear = () => {
        const updatedInvoiceFile = {};
        analytics.track('payor', 'upload-invoice-file', {
          action: 'file-removed',
          vendorId: this.state.vendorId,
        });
        this.setState((prevState) => ({
          ...prevState,
          invoiceFile: updatedInvoiceFile,
        }));
      };

      loadVendorLogo = async () => {
        const { link } = this.props;
        const { fileStorageUrl } = await filesApi.getLogoByVendorHandle(link);

        this.setState({ logoUrl: fileStorageUrl });
      };

      navigateToGuestPage = (
        url: string,
        data: Record<string, any>,
        exitIframe = false,
        shouldReplaceCurrent = false
      ) => {
        const { logoUrl, ...rest } = this.state;

        this.props.navigate(url, shouldReplaceCurrent, { ...this.props.locationState, ...rest, ...data }, exitIframe);
      };

      render() {
        return (
          <Component
            {...this.state}
            {...this.props}
            onChange={this.onChange}
            onInvoiceFileChange={this.onInvoiceFileChange}
            onInvoiceFileClear={this.onInvoiceFileClear}
            navigateToGuestPage={this.navigateToGuestPage}
          />
        );
      }
    };
  };
}
