import * as React from 'react';
import styled from 'styled-components';
import { MCBClose } from 'src/components/common/MCBClose';
import { MICard } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { BillStatus } from 'src/pages/bill/components/BillStatus';
import { devices } from 'src/theme/appDevices';
import type { StatusInfoType } from 'src/utils/types';
import { GuestPaymentInfo } from './GuestPaymentInfo';

type Props = {
  onKeyDown: (event: any) => void;
  children: React.ReactNode;
  onSignUpClick?: () => void;
  goToAccount?: () => void;
  showNotFoundCard: boolean;
  showPaymentProgressStatus?: boolean;
  statusInfo: StatusInfoType;
  isActionRegister?: boolean;
  blockCCMoneyIn: boolean;
  blockDebitCardMoneyIn: boolean;
};

const GuestCardLayout = ({
  children,
  onKeyDown,
  showNotFoundCard,
  showPaymentProgressStatus = false,
  statusInfo,
  isActionRegister = true,
  onSignUpClick,
  goToAccount,
  blockCCMoneyIn,
  blockDebitCardMoneyIn,
}: Props) => (
  <BackgroundContainer>
    <TopElementsContainer>
      <MCBClose />
    </TopElementsContainer>
    <MICardContainerWrapper>
      <MICardContainer>
        <MICard mode={statusInfo ? 'regular' : 'mainSingleScreen'}>
          {!showNotFoundCard && showPaymentProgressStatus && <BillStatus statusInfo={statusInfo} />}
          <CardInner onKeyDown={onKeyDown}>{children}</CardInner>

          {showPaymentProgressStatus && (
            <SignUpOfferContainer>
              {isActionRegister ? (
                <>
                  <SignUpTextOffer>
                    <MIFormattedText label="guests.progressPayment.wantToTrackPayments" />
                  </SignUpTextOffer>
                  <Button onClick={onSignUpClick} label="guests.progressPayment.buttonText" size={ButtonSizes.lg} />
                </>
              ) : (
                <Button onClick={goToAccount} label="guests.progressPayment.goToYourAccount" size={ButtonSizes.lg} />
              )}
            </SignUpOfferContainer>
          )}
          {!showNotFoundCard && !showPaymentProgressStatus && (
            <GuestPaymentInfo blockCCMoneyIn={blockCCMoneyIn} blockDebitCardMoneyIn={blockDebitCardMoneyIn} />
          )}
        </MICard>
      </MICardContainer>
    </MICardContainerWrapper>
  </BackgroundContainer>
);

const BackgroundContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white.opaque};
  width: 100%;
  height: '100%';
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopElementsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 3.5rem 0;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 1.6rem 1.6rem 0;
  }

  background: ${(props) => props.theme.colors.white.opaque};
  position: sticky;
  left: 0px;
  right: 0px;
  top: 0px;
  padding: 2rem !important;
  justify-content: flex-end;
  z-index: 1010;
`;

const MICardContainer = styled.div`
  position: relative;
  max-width: 45rem;
  margin: 1.8rem 1.6rem 0;
  text-align: center;
  background-color: ${(props) => props.theme.colors.white.opaque};
  border-radius: 0.8rem;
  width: 100%;

  @media ${devices.mobile}, ${devices.phablet} {
    margin: 0.4rem 1rem 3.6rem 1rem;
  }

  @media ${devices.desktop}, ${devices.tablet} {
    margin-bottom: 6rem;
  }
  width: 450px;
  margin-top: 0px !important;
`;

const MICardContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CardInner = styled.div`
  @media ${devices.mobile} {
    padding: 4rem 2rem 3rem;
  }
  @media ${devices.phablet} {
    padding: 5rem 2rem 4rem;
  }

  @media ${devices.desktop}, ${devices.tablet} {
    padding: 4rem;
  }
`;

const SignUpTextOffer = styled.div`
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  color: ${(props) => props.theme.text.color.main};
  margin-bottom: 2.6rem;
`;

const SignUpOfferContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 4.8rem;
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.grey};
`;

export default GuestCardLayout;
