export { FeatureFlags } from './featureFlags';

export enum Currency {
  USD = 'USD',
}

export enum CurrencySign {
  USD = '$',
}

export enum Country {
  US = 'US',
  PR = 'PR',
  VI = 'VI',
  GU = 'GU',
  MP = 'MP',
  CA = 'CA',
  UK = 'UK',
  AL = 'AL',
  AD = 'AD',
  AM = 'AM',
  AT = 'AT',
  BY = 'BY',
  BE = 'BE',
  BA = 'BA',
  BG = 'BG',
  CH = 'CH',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DK = 'DK',
  EE = 'EE',
  ES = 'ES',
  FO = 'FO',
  FI = 'FI',
  FR = 'FR',
  GE = 'GE',
  GI = 'GI',
  GR = 'GR',
  HU = 'HU',
  HR = 'HR',
  IE = 'IE',
  IS = 'IS',
  IT = 'IT',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MC = 'MC',
  MK = 'MK',
  MT = 'MT',
  NO = 'NO',
  NL = 'NL',
  PL = 'PL',
  PT = 'PT',
  RO = 'RO',
  RU = 'RU',
  SE = 'SE',
  SI = 'SI',
  SK = 'SK',
  SM = 'SM',
  TR = 'TR',
  UA = 'UA',
  VA = 'VA',
}

export enum DeliveryType {
  ACH = 'ach',
  CHECK = 'check',
  VIRTUAL = 'virtual',
  CARD = 'card',
  RPPS = 'rpps',
  INTERNATIONAL = 'international',
  VIRTUAL_CARD = 'virtual-card',
  PUSH_TO_DEBIT = 'push to debit',
}

export const NON_EDITABLE_DELIVERY_METHOD_TYPES = [
  DeliveryType.VIRTUAL_CARD,
  DeliveryType.RPPS,
  DeliveryType.INTERNATIONAL,
];

export const ADDRESS_DEFAULTS = {
  NO_GOOGLE_PLACE_ID: '-1',
};

export enum ManualAddress {
  SUGGESTED = '1',
  ORIGINAL = '2',
}

export const FEES = {
  FREE: 0,
  PAID: 2.9,
};

export enum CreditCardFeePayment {
  PAYOR = 'payor',
  VENDOR = 'vendor',
}

export enum BankAccountType {
  CHECKING = 'checking',
}

/**
 * In case you want the query param status, use PayTabs.
 */
export enum BillStatus {
  UNPAID = 'unpaid',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
  SCHEDULED = 'scheduled',
}

export enum DashboardTabs {
  Unpaid = 'inbox',
  Scheduled = 'scheduled',
  Paid = 'paid',
}

export enum PaymentStatus {
  SCHEDULED = 'scheduled',
  COMPLETED = 'completed',
  PROCESSED = 'processed',
  IN_PROGRESS = 'in-progress',
  BLOCKED = 'blocked',
  FAILED = 'failed',
}

export enum RiskStatus {
  APPROVED = 'approved',
  DECLINED = 'declined',
  PENDING = 'pending',
}

export enum InstallmentStatus {
  Draft = 'draft',
  Scheduled = 'scheduled',
  Committed = 'committed',
  Completed = 'completed',
  Sent = 'sent',
  Failed = 'failed',
  Acknowledged = 'acknowledged',
  Cleared = 'cleared',
  Settled = 'settled',
  Terminated = 'terminated',
  Retry = 'retry',
}

export enum CouponStatus {
  CREATED = 'created',
  ACTIVATED = 'activated',
  EXPIRED = 'expired',
  FULFILLED = 'fulfilled',
}

export enum PaymentApprovalStatus {
  SKIP = 'skip',
  PENDING = 'pending',
  DECLINED = 'declined',
  APPROVED = 'approved',
}

export enum PaymentApprovalDecisionStatus {
  DECLINED = 'declined',
  APPROVED = 'approved',
}

export enum PaymentCollectStatus {
  BLOCKED = 'blocked',
  READY = 'ready',
  PROCESSING = 'processing',
  SENT = 'sent',
  COMPLETED = 'completed',
  FAILED = 'failed',
  RETRY = 'retry',
  TECHNICAL_FAILURE = 'technicalFailure',
  CANCEL = 'cancel',
  CANCELED = 'canceled',
  SCHEDULED = 'scheduled',
  INVALID = 'invalid',
  IN_PROGRESS = 'in-progress',
  CLEARED = 'cleared',
  SETTLED = 'settled',
  CANCEL_SCHEDULED = 'cancel-scheduled',
  CANCEL_IN_PROGRESS = 'cancel-in-progress',
  CANCEL_SENT = 'cancel-sent',
  CANCEL_CLEARED = 'cancel-cleared',
  CANCEL_SETTLED = 'cancel-settled',
  CANCEL_FAILED = 'cancel-failed',
  CANCEL_ACKNOWLEDGED = 'cancel-acknowledged',
}

export const REFUND_COLLECT_STATUSES = [
  PaymentCollectStatus.CANCEL_SCHEDULED,
  PaymentCollectStatus.CANCEL_IN_PROGRESS,
  PaymentCollectStatus.CANCEL_SENT,
  PaymentCollectStatus.CANCEL_SETTLED,
  PaymentCollectStatus.CANCEL_CLEARED,
  PaymentCollectStatus.CANCEL_ACKNOWLEDGED,
];

export enum PaymentDeliverStatus {
  ACKNOWLEDGED = 'acknowledged',
  COLLECTED = 'collected',
  DEPOSITED = 'deposited',
  BLOCKED = 'blocked',
  READY = 'ready',
  PROCESSING = 'processing',
  SENT = 'sent',
  COMPLETED = 'completed',
  FAILED = 'failed',
  RETRY = 'retry',
  TECHNICAL_FAILURE = 'technicalFailure',
  CANCEL = 'cancel',
  CANCELED = 'canceled',
  SCHEDULED = 'scheduled',
  INVALID = 'invalid',
  IN_PROGRESS = 'in-progress',
  IN_TRANSIT = 'in transit',
  CLEARED = 'cleared',
  SETTLED = 'settled',
  CANCEL_SCHEDULED = 'cancel-scheduled',
  CANCEL_IN_PROGRESS = 'cancel-in-progress',
  CANCEL_SENT = 'cancel-sent',
  CANCEL_CLEARED = 'cancel-cleared',
  CANCEL_SETTLED = 'cancel-settled',
  CANCEL_FAILED = 'cancel-failed',
  INITIATED = 'initiated',
  REFUNDED = 'refunded',
}

export enum ScreenMode {
  VIEW = 'view',
  EDIT = 'edit',
}

export enum DataOrigin {
  INTUIT = 'intuit',
}

export enum AccountingSoftware {
  QUICKBOOKS = 'Quickbooks Online',
  QUICKBOOKS_DESKTOP = 'Quickbooks Desktop',
  FRESHBOOKS = 'Freshbooks',
  MICROSOFT_DYNAMICS_365 = 'Dynamics 365 Business Central',
  XERO = 'Xero',
  ORACLE_NETSUITE = 'Oracle Netsuite',
  RIGHT_NETWORKS = 'Right Networks',
  SAGE_INTACCT = 'Sage Intacct',
  KASHOO = 'Kashoo',
  MICROSOFT_DYNAMICS = 'Microsoft Dynamics',
  QUICKBOOKS_FOR_MAC = 'Quickbooks For Mac',
  QUICKEN = 'Quicken',
  SAGE = 'Sage',
  SAGE_50 = 'Sage 50',
  SAP = 'Sap',
  THOMSON = 'Thomson Reuters Cbs',
  WAVE = 'Wave',
  YARDI = 'Yardi',
  ZOHO_BOOKS = 'Zoho Books',
  NONE = 'none',
}

export enum ProgressBarFlavor {
  WIZARD = 'wizard',
  DASHBOARD = 'dashboard',
  MELIO_ME = 'melio.me',
}

export enum SingleSelectFlavor {
  DEFAULT = 'default',
  INLINE = 'inline',
  TABLE = 'table',
  DS = 'ds',
}

export enum MiMoneyFlavor {
  DEFAULT = 'default',
  POSITIVE = 'positive',
  POSITIVE_WHITE = 'positive-white',
  NEGATIVE = 'negative',
}

export enum TextInputSize {
  INLINE = 'inline',
  WIZARD = 'wizard',
}

export enum FormType {
  WIZARD = 'wizard',
  DETAILS = 'details',
}

export enum FormControlType {
  TEXT = 'text',
  LINK = 'link',
  CURRENCY = 'currency',
  ADDRESS = 'address',
  DATE = 'date',
  SELECT = 'select',
  DROP_DOWN = 'drop-down',
  RADIO = 'radio',
  TEL = 'tel',
  SELECT_SLIDER = 'select-slider',
  FILE = 'file',
  PASSWORD = 'password',
  BLOCK = 'block',
}

export const FORM_AUTOCOMPLETE = {
  // basically, any unrecognized string will work, see here:
  // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
  OFF: 'autocomplete-off',
};

export const FORM_MAX_LENGTH = {
  NOTE: 50,
};

export enum AddressFormControlMode {
  WITH_MAP = 'with-map',
  INLINE = 'inline',
  FORM = 'form',
}

export const ACCEPT_FILES = {
  ALL: '*',
  BILL_ATTACHMENT: 'image/*, application/pdf',
  IMAGES: 'image/*',
  TEXT_LISTS: 'text/csv',
};

export enum FundingType {
  ACH = 'ach',
  CARD = 'card',
  INSTALLMENTS = 'installments',
}

export const FUNDING_LOGO = {
  DEFAULT: 'default',
};

export enum FundingSourceOrigin {
  PLAID = 'plaid',
  MICRO = 'micro',
  MANUAL = 'manual',
  TABAPAY = 'taba-pay',
  QBCASH = 'qbcash',
}

export const FUNDING_SOURCE_LIMIT = {
  ACH_UNVERIFIED: 2,
};

export enum CardNetworkTypes {
  VISA = 'Visa',
  MASTERCARD = 'MasterCard',
  AMEX = 'Amex',
}

export const UNSUPPORTED_CARD_NETWORK_TYPES_FOR_VIRTUAL_CARD = [CardNetworkTypes.VISA, CardNetworkTypes.AMEX];

export enum CardTypes {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export enum BillPageType {
  EDIT = 'edit',
  CREATE = 'create',
}

export enum TaxIdType {
  SSN = 'SSN',
  ITIN = 'ITIN',
  EIN = 'EIN',
}

export enum DialogType {
  ALERT = 'ALERT',
  CONFIRM = 'CONFIRM',
}

export enum DialogVariants {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export enum ButtonsDirections {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum ButtonsRowPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
}

export enum ImagePosition {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
}

export enum LogoFillType {
  MELIO = 'melio',
  DARK = 'dark',
  LIGHT = 'light',
  WHITE = 'white',
  QB = 'qb',
}

export enum KybStatus {
  ACCEPTED = 'accepted',
  UNDECIDED = 'undecided',
  DENIED = 'denied',
}

export enum MqlType {
  UNDECIDED = 'undecided',
  QUALIFIED = 'qualified',
  UNQUALIFIED = 'unqualified',
}

export enum SettingFeeCostType {
  FIXED = 'fixed',
  PERCENT = 'percent',
}

export enum CompanyFormPage {
  SETTINGS = 'settings',
  ONBOARDING = 'onboarding',
  NO_POST_ONBOARDING = 'noPostOnboarding',
}

export const PAGINATION = {
  DEFAULT_VISIBLE_PAGES_COUNT: 5,
  DEFAULT_LIMIT: 20,
  DEFAULT_START: 0,
};

export enum PaginationItemsType {
  BUTTON = 'button',
  LINK = 'link',
}

export const TIMEOUTS = {
  CLOSE_MODAL: 2000,
  MELIO_ME_INFO_MODAL: 2800,
  CLOSE_NOTIFICATION: 3000,
};

export enum RegistrationOrigin {
  APP = 'app',
  LANDING = 'landing',
  QUICKBOOKS = 'quickbooks',
  QUICKBOOKS_MOBILE = 'quickbooksMobile',
  QUICKBOOKS_ROCKETMAN_IOS = 'quickbooksRocketmanIOS',
  QUICKBOOKS_ROCKETMAN_ANDROID = 'quickbooksRocketmanAndroid',
  QUICKBOOKS_QBM_IOS = 'quickbooksQbmIOS',
  QUICKBOOKS_QBM_ANDROID = 'quickbooksQbmAndroid',
  LANDING_NO_VERIFICATION = 'landingNoVerification',
  LANDING_WITH_PROMO = 'landingWithPromo',
  ADD_COMPANY = 'addCompany',
}

export enum RegistrationFlow {
  REGULAR = 'regular',
  VENDOR = 'vendor',
  GUEST = 'guest',
  PUSH_TO_DEBIT = 'push-to-debit',
  PUSH_TO_FAST_ACH = 'push-to-fast-ach',
  PUSH_TO_RTP = 'push-to-rtp',
  QUICKBOOKS = 'quickbooks',
  QBO_INTEGRATION = 'qbo-integration',
  MCB = 'checkout-button',
  MSN_PORTAL_PUBLIC_FORM = 'msn-portal-public-form',
  MSN_PORTAL_SALES_FORM = 'msn-portal-sales-form',
  SPEND_MANAGEMENT = 'spend-management',
}

export enum LoginFlow {
  SPEND_MANAGEMENT = 'spend-management',
}

export enum OrganizationCreateOrigin {
  QBO_INTEGRATION = 'qbo-integration',
  ADD_COMPANY = 'add-company',
  USER_SIGNUP = 'user-signup',
  GUEST = 'guest',
  QBM_INTEGRATION = 'qbm-integration',
  QBR_INTEGRATION = 'qbr-integration',
  QBR_IOS = 'qbr-ios',
  QBR_ANDROID = 'qbr-android',
  QBM_IOS = 'qbm-ios',
  QBM_ANDROID = 'qbm-android',
  QBDT_INTEGRATION = 'qbdt-integration',
  QBDT_WINDOWS = 'qbdt-windows',
  QBDT_MAC = 'qbdt-mac',
  PUSH_TO_DEBIT = 'push-to-debit',
  MSN = 'msn-portal',
  SPEND_MANAGEMENT = 'spend-management',
}

export enum PaymentCreateFlowOrigin {
  PAY = 'pay',
  REQUEST = 'request',
  QBO_INTEGRATION = 'qbo-integration',
  QBR_IOS = 'qbr-ios',
  QBR_ANDROID = 'qbr-android',
  QBM_IOS = 'qbm-ios',
  QBM_ANDROID = 'qbm-android',
}

export enum PaymentRequestCreateOrigin {
  MELIOME = 'melio-me',
  MELIOME_GENERIC_LINK = 'melio-me-generic-link',
  REQUEST = 'payment-request',
  MCB = 'checkout-button',
  EXTERNAL_API = 'melio-me-external-api',
  QBO = 'quickbooks-online',
  CODAT = 'codat-integration',
}

export enum PendingReasons {
  NOT_VERIFIED = 'notVerified',
  RISK_NEGATIVE = 'riskNegative',
  APPROVAL = 'approval',
}

export const RETURNED_CHECK_REASONS = {
  'RETURNED TO SENDER': 'Mail returned to sender due to contents that are nonmailable.',
  'NOT DELIVERABLE AS ADDRESSED - UNABLE TO FORWARD (UTF)':
    'Mail undeliverable at address given; no change-of-address order on file; forwarding order expired.',
  'INSUFFICIENT ADDRESS (IA)':
    'Mail without number, street, box number, route number, or geographical section of city or city and state omitted and correct address not known.',
  'returned by vendor': 'No reason provided. Please contact your vendor for more information.',
  'NO SUCH NUMBER (NSN)': 'Addressed to nonexistent number and correct number not known.',
  'NO MAIL RECEPTABLE (NMR)': 'Addressee failed to provide a receptacle for receipt of mail.',
  'ATTEMPTED NOT KNOWN (ANK)': 'Delivery attempted, addressee not known at place of address.',
  'NO SUCH STREET (NSS)': 'Addressed to nonexistent street and correct street not known.',
  'UNCLAIMED (UNC)': 'Addressee abandoned or failed to call for mail.',
  'VACANT (VAC)': 'House, apartment, office, or building not occupied.',
  'REFUSED (REF)': 'Addressee refused to accept mail or pay postage charges on it.',
  OTHER: 'No reason provided.',
};

/* Note: if you add a trait here, the app will try to update it in the DB */
export enum DbAnalyticsTraits {
  FIRST_TIME_PAY = 'first_time_pay',
  ADDED_FUNDING = 'added_funding',
  CREATE_A_BILL = 'create_a_bill',
  LINK_ACCOUNTING_SOFTWARE = 'link_accounting_software',
  EMAIL_VERIFIED = 'Email_Verified',
  IS_BUSINESS_EMAIL = 'is_business_email',
  ADDED_DELIVERY_METHOD = 'added_delivery_method',
}

export enum ContactsTab {
  CUSTOMERS = 'customers',
  VENDORS = 'vendors',
}

export enum ServerResponseCodes {
  OK = 'OK',
}

export enum TransactionTypes {
  ACH = 'ach',
  CHECK = 'check',
  CARD = 'card',
}

export enum TransactionDestinations {
  VENDOR = 'vendor',
  ORGANIZATION = 'organization',
}

export enum TransactionDirections {
  DEBIT = 'debit',
  CREDIT = 'credit',
}

export enum TransactionStatuses {
  COMPLETED = 'COMPLETED',
  AUTHORIZATION = 'AUTHORIZATION',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
}

export enum AddFundingSourceWizardOrigin {
  PAY_BILL = 'funding-source-pay-bill',
  GUEST_ONBOARDING = 'funding-source-guest-onboarding',
  SETTINGS = 'funding-source-settings',
  BATCH_PAY_BILLS = 'funding-source-batch-pay-bills',
  BILLING = 'funding-source-billing-fee',
}

export enum LocalStorageKeys {
  INTUIT_RETURN_URL = 'intuitReturnUrl',
  INTUIT_RETURN_APP_URL = 'IntuitReturnAppUrl',
  QUICKBOOKS_CHOOSE_REALM_ID = 'quickbooksReturnRelamId',
  IS_MELIO_ME_INFO_MODAL_SHOWED = 'isMelioMeInfoModalShowed',
}

export enum WizardProgressBar {
  WITHOUT_SUBSTEPS = 'without-substeps',
  WITH_SUBSTEPS = 'with-substeps',
}

export const VENDOR_ONBOARDING_PROGRESS_BAR_STEPS = [
  'onboarding.vendorCompanyInfo.progressBarSteps.businessInfo',
  'onboarding.vendorCompanyInfo.progressBarSteps.identity',
  'onboarding.vendorCompanyInfo.progressBarSteps.getPaid',
];

export enum DeliveryMethodOrigin {
  ONBOARDING = 'delivery-method-onboarding',
  MSN_PORTAL_ONBOARDING = 'msn-portal-delivery-method-onboarding',
  VENDOR_DETAILS = 'delivery-method-vendor-details',
  NEW_VENDOR = 'new-vendor',
  NEW_VENDOR_FROM_ONBOARDING = 'new-vendor-from-onboarding',
  OWNED_VENDOR_SETTINGS = 'delivery-method-owned-vendor-settings',
  CREATE_OWNED_VENDOR_FROM_REQUESTS_LIST = 'delivery-method-create-owned-vendor-from-requests-list',
  CREATE_OWNED_VENDOR_FROM_NEW_REQUEST = 'delivery-method-create-owned-vendor-from-new-request',
  PAY_BILL = 'delivery-method-pay-bill',
  EDIT_PAYMENT = 'edit-payment',
  BATCH_PAY_BILLS = 'delivery-method-batch-pay-bills',
  MSN_PORTAL_GENERIC_LINK_CREATION = 'msn-portal-generic-link-creation',
  GET_PAID = 'delivery-method-get-paid',
  MSN_CLAIM_ACCOUNT = 'msn-claim-account',
  GET_PAID_SHOW_HANDLE = 'delivery-method-get-paid-show-handle',
  GET_PAID_SHARE_INVOICE_URL = 'delivery-method-get-paid-share-invoice-url',
  GET_PAID_CREATE_PAYMENT_REQUEST = 'delivery-method-get-paid-create-payment-request',
  GET_PAID_CREATE_BATCH_PAYMENT_REQUESTS = 'delivery-method-get-paid-create-batch-payment-requests',
}

export enum DeliveryMethodCheckFlow {
  UNILATERAL = 'delivery-method-check-go-unilateral',
  CONFIRM = 'delivery-method-check-confirm-address',
}

export enum CopyLinkSize {
  NORMAL = 'normal',
  BIG = 'big',
}

export enum CopyLinkColor {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  PAY = 'pay',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  DESTRUCTIVE = 'destructive',
  CANCEL = 'cancel',
  REMOVE = 'remove',
  EMPTY = 'empty',
  INTUIT = 'intuit',
  UNDO = 'undo',
}

export enum ButtonSize {
  SMALL = 'small',
  TINY = 'tiny',
  NORMAL = 'normal',
  VERY_SMALL = 'very-small',
}

export enum BadgeSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  X_SMALL = 'x-small',
  X_LARGE = 'x-large',
}

export const DEFAULT_LOCATION = {
  lat: 40.77,
  lng: -74,
};

export enum BillFrequency {
  MONTHLY = 'monthly',
  WEEKLY = 'weekly',
  ONE_TIME = 'one-time',
}

export enum DeletePaymentAction {
  SINGLE = 'single',
  RECURRING_CURRENT = 'current',
  RECURRING_ALL = 'all',
}

export enum PaymentTrackerStageStatus {
  SCHEDULED = 'scheduled',
  COMPLETED = 'completed',
  FAILED = 'failed',
  PENDING = 'pending',
}

export enum PaymentRequestStatus {
  CREATED = 'created',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  MARKED_AS_PAID = 'markedAsPaid',
  CANCELLED_BY_CUSTOMER = 'cancelledByCustomer',
}

export enum PaymentRequestApprovalStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  DENIED = 'denied',
  SKIPPED = 'skipped',
}

export enum InvoiceStatus {
  CREATED = 'created',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  CANCELLED_BY_CUSTOMER = 'cancelledByCustomer',
  PAID = 'paid',
  FAILED = 'failed',
  MARKED_AS_PAID = 'markedAsPaid',
  IN_PROGRESS = 'inProgress',
}

export enum PaymentRequestTabs {
  UNSENT = 'unsent',
  SENT = 'sent',
  SCHEDULED = 'scheduled',
  PAID = 'paid',
}

export enum GetProTabs {
  INVOICES = 'invoices',
  REQUESTS = 'requests',
  IN_PROGRESS = 'inProgress',
  PAID = 'paid',
}

export enum PayTabs {
  INBOX = 'unpaid',
  SCHEDULED = 'scheduled',
  PAID = 'paid',
}

export const PAY_DEFAULT_TAB = PayTabs.INBOX;

export enum PaymentRequestFilter {
  ALL = 'all',
  FAILED = 'failed',
  UNSENT = 'unsent',
  SENT = 'sent',
}

export enum PaymentRequestActions {
  MARK_CREATED_LAST_7_DAYS = 'mark_created_last_7_days',
  MARK_CREATED_SINCE = 'mark_created_since',
}

export enum TrackingStatuses {
  COMPLETED = 'COMPLETED',
  CURRENT = 'CURRENT',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export enum VerifyFundingSourceMicroDepositsErrorCodes {
  NOT_FOUND = 'RSC01',
  ERR_VERIFY_MICRO_DEPOSITS = 'RSC11',
  CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS = 'RSC12',
  INVALID_TOKEN = 'RSC13',
}

export enum VerifyDeliveryMethodMicroDepositsErrorCodes {
  NOT_FOUND = 'DLM01',
  ERR_VERIFY_MICRO_DEPOSITS = 'DLM12',
  CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS = 'DLM13',
}

export enum MicroDepositsErrorsEnum {
  ALREADY_SENT = 'ALREADY_SENT',
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',
  MICRO_DEPOSITS_AMOUNT_LIMITATION = 'MICRO_DEPOSITS_AMOUNT_LIMITATION',
  BANK_ACCOUNT_NOT_FOUND = 'BANK_ACCOUNT_NOT_FOUND',
}

export enum InputPatterns {
  NUMBERS = '[0-9]*',
}

export enum CompanyInfoOnboardingOrigin {
  SETUP = 'setup',
  PAYMENT_REQUEST = 'payment-request',
  GUEST_PAYMENT_REQUEST = 'guest_payment-request',
  SETTINGS = 'settings',
  ACCOUNTANT_ADD_COMPANY = 'accountant-add-company',
}

export enum NotificationVariant {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export enum Role {
  OWNER = 'owner',
  ADMIN = 'admin',
  CONTRIBUTOR = 'contributor',
  ACCOUNTANT = 'accountant',
}

export enum TagVariant {
  BRAND = 'brand',
  LIGHT = 'light',
  DARK = 'dark',
  WARNING = 'warning',
  ERROR = 'error',
  DISABLED = 'disabled',
  EXPIRED = 'expired',
  SUCCESS = 'success',
  DEPOSITED = 'deposited',
  ACH_DEPOSITED = 'ach-deposited',
}

export const RISK_MAX_PAYMENT_AMOUNT = 1000000;

export const RISK_BILL_AMOUNT = {
  REQUIRES_INVOICE_FILE: 10000,
  REQUIRES_GOODS_CONFIRMATION: 3000,
  REQUIRES_GOODS_CONFIRMATION_10K: 10000,
  BIG_TRANSFER_THRESHOLD: 100000,
};

export enum EventMappingName {
  DEFAULT = 'DEFAULT',
}

export enum FastCheckDeliveryType {
  EXPRESS = 'express-check',
  OVERNIGHT = 'overnight-check',
}

export enum DeliveryPreferenceType {
  EXPEDITED_ACH = 'expedited-ach',
  RTP = 'rtp',
}

export const EXPEDITED_DELIVERY_TYPES = ['expedited-ach', FastCheckDeliveryType.OVERNIGHT];

export const FAST_DELIVERY_TYPES = [...EXPEDITED_DELIVERY_TYPES, FastCheckDeliveryType.EXPRESS];

export const DELIVERY_OPTION_TYPES = [...EXPEDITED_DELIVERY_TYPES, ...FAST_DELIVERY_TYPES];

export enum LinkAccountingSoftwareError {
  ACCESS_DENIED = 'access_denied',
  DIFF_REALMID = 'diff_realmid',
}

export enum VerifiedStatus {
  PLAID_VERIFIED = 'plaid-verified',
  NOT_VERIFIED = 'not-verified',
  MICRO_DEPOSIT_VERIFIED = 'micro-deposit-verified',
  SOFT_VERIFIED = 'soft-verified',
}

export enum DeliveryDateFontType {
  MEDIUM = 'medium',
  REGULAR = 'regular',
  HINT = 'hint',
  SMALL = 'small',
}

export enum NotificationCardTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  HELP = 'help',
}

export enum ChecksDelaysTooltipTypes {
  WARNING = 'warning',
  INFO = 'info',
  INFO_DEFAULT = 'info-default',
}

export const REGEX = {
  CHECK_PRINT_NAME: /[^a-zA-Z0-9,:;/&#@‘\-.() ]|  +/g,
};

export enum FailedPaymentType {
  FAILED_PAYMENT = 'failedPayment',
  FAILED_TO_COLLECT = 'failedToCollect',
  FAILED_TO_DELIVER = 'failedToDeliver',
}

export enum FailedPaymentMessage {
  VIRTUAL_CARD_EXPIRED = 'virtualCardExpired',
  RECEIVER_REFUSED_CREDIT_ENTRY = 'receiverRefusedCreditEntry',
  BANK_ACCOUNT_UNAUTHORIZED = 'bankAccountUnauthorized',
  RETURNED_CHECK = 'returnedCheck',
}

export enum DeliveryMethodEmailReminderStatus {
  SENT = 'SENT',
  NOT_SENT = 'NOT SENT',
}

export const BANK_ACCOUNT_EXIST = 'DLM07';

export enum CalendarType {
  GOOGLE = 'google',
  YAHOO = 'yahoo',
  OUTLOOKCOM = 'outlookcom',
  OUTLOOK = 'outlook',
  APPLE = 'apple',
}

export enum IntentType {
  PAY = 'pay',
  GET_PAID = 'get-paid',
}

export enum IntuitUserType {
  NEW = 'new',
  QBO_MULTI_ORG = 'qboMultiOrg',
  QBO_SINGLE_ORG = 'qboSingleOrg',
  KNOWN = 'known',
}

export enum ToggleType {
  COMPLETED = 'completed',
  OPEN = 'open',
}

export enum PayEditLocationName {
  DATE = 'date',
  FUNDING = 'funding',
  DELIVERY_METHOD = 'deliveryMethod',
  DELIVERY_METHOD_ACH = 'deliveryMethodAch',
  CONFIRM = 'confirm',
  MEMO = 'memo',
  FAST_PAYMENT = 'fastPayment',
}

export enum AccessLevel {
  NONE = 'none',
  FULL = 'full',
}

export enum InternationalIdentifierType {
  IBAN = 'iban',
  SORT = 'sort',
  SWIFT = 'swift',
  BIC_SWIFT = 'bic_swift',
}

export enum VirtualCardStatus {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
}

export const FULL_STORY_MASK_RULE_CLASS = 'fs-mask';

export enum ApprovalWorkflowCreatorsGroup {
  ALL = 'all',
  SOME = 'some',
}

export enum UserAuthType {
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
  EMAIL_NOT_VERIFIED = 'emailNotVerified',
  GUEST = 'guest',
  TWO_FA_REQUIRED = '2FARequired',
}

export enum OrgPreferencesTypeKeys {
  showPaymentAuditLog = 'showPaymentAuditLog',
  featureInternalTesting = 'featureInternalTesting',
  onboardingBookkeeperNumOfCompanies = 'onboardingBookkeeperNumOfCompanies',
  onboardingCompanyTPV = 'onboardingCompanyTPV',
  onboardingIndustryId = 'onboardingIndustryId',
  onboardingSubIndustryId = 'onboardingSubIndustryId',
  getPaidProLayout = 'getPaidProLayout',
  regularBatchPayments = 'regularBatchPayments',
  vendorDirectory = 'vendorDirectory',
  partialPayments = 'partialPayments',
  isVendorAbsorbedFee = 'isVendorAbsorbedFee',
  migrateASMessage = 'migrateASMessage',
  shownPartialPaymentsAmount = 'shownPartialPaymentsAmount',
  uninvitedOwnerEmail = 'uninvitedOwnerEmail',
  getPaidOverrideLegalInfo = 'getPaidOverrideLegalInfo',
  invoiceGenerationMessage = 'invoiceGenerationMessage',
  defaultFundingSourceId = 'defaultFundingSourceId',
  vendorDashboardDisplay = 'vendorDashboardDisplay',
  partialPaymentsUI = 'partialPaymentsUI',
  payInboxAnnouncement = 'payInboxAnnouncement',
  shownCombineBillsTooltip = 'shownCombineBillsTooltip',
  msnBusinessType = 'msnBusinessType',
  isMigratedToPlatform = 'isMigratedToPlatform',
  isRevertedToLegacy = 'isRevertedToLegacy',
  isSpendManagementEnabled = 'isSpendManagementEnabled',
  areClientsOfFirmMigratedToPlatform = 'areClientsOfFirmMigratedToPlatform',
  arProcessingFeeExempt = 'arProcessingFeeExempt',
  isArProcessingFeeExplanationBannerDismissed = 'isArProcessingFeeExplanationBannerDismissed',
  isArEnabled = 'isArEnabled',
}

export enum CompanyType {
  SMB = 'smb',
  ACCOUNTING_FIRM = 'accounting-firm',
}

export enum VendorManagedByEnum {
  SHARED = 'shared',
  BILLER = 'biller',
  MSN = 'msn',
}

export enum IntegrationTypes {
  CODAT = 'codat',
  NATIVE = 'native',
  QBDT = 'qbdt',
}

export enum ConnectionStatus {
  CONNECTED = 'connected',
  INVALID_CREDENTIALS = 'invalid-credentials',
  INVALID_SUBSCRIPTION = 'invalid-subscription',
  DISCONNECTED = 'disconnected',
  UNKNOWN_ERROR = 'error',
}

export enum SyncStatus {
  STARTED = 'started',
  PENDING = 'pending',
  SYNCED = 'synced',
  FAILURE = 'failure',
}

export enum UserPreferencesKey {
  AccountingSoftwareType = 'accountingSoftwareType',
  IsClosedBillsZeroState = 'isClosedBillsZeroState',
  IsClosedRequestsZeroState = 'isClosedRequestsZeroState',
  AllowCompaniesSwitcher = 'allowCompaniesSwitcher',
  GetPaidProLayout = 'getPaidProLayout',
  QbDashboardSeen = 'qbDashboardSeen',
  ShowQbDashboardInternalEntrypoints = 'showQbDashboardInternalEntrypoints',
  ShownBillerVisibilityTooltipBill = 'shownBillerVisibilityTooltipBill',
  ShownBillerVisibilityTooltipVendor = 'shownBillerVisibilityTooltipVendor',
  ShownBillerVisibilityModal = 'shownBillerVisibilityModal',
  GetPaidProWelcomeModalShown = 'getPaidProWelcomeModalShown',
  StartedBillFlow = 'startedBillFlow',
  PlaygroundVisited = 'playgroundVisited',
  PlaygroundModalDontShowAgain = 'playgroundModalDontShowAgain',
  PlaygroundModalSeenCount = 'playgroundModalSeenCount',
  CheckFeesNotificationCount = 'checkFeesNotificationCount',
  BillInboxAnnouncementShown = 'billInboxAnnouncementShown',
  MsnEmptyOrganizationCreated = 'msnEmptyOrganizationCreated',
  FinancingFundingSourceFTXViewsCount = 'financingFundingSourceFTXViewsCount',
  batchBulkUncombinedSeen = 'batchBulkUncombinedSeen',
  financingFundingSourceDisabledViewsCount = 'financingFundingSourceDisabledViewsCount',
}

export enum RefundReason {
  IncorrectVendorDetails = 'incorrectVendorDetails',
  IncorrectPaymentAmount = 'incorrectPaymentAmount',
  PaidViaAnotherProvider = 'paidViaAnotherProvider',
  SwitchingPaymentMethod = 'switchingPaymentMethod',
  GoodsServicesIssues = 'goodsServicesIssues',
  BadCustomerExperience = 'badCustomerExperience',
  Other = 'other',
}

export enum SessionStorageKey {
  ShownPayDemoModeModal = 'shownPayDemoModeModal',
}

export enum PaymentTypes {
  REFUND = 'refund',
  REGULAR_PAYMENT = 'regularPayment',
}

export enum RiskTypesEnum {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum BillingFeeType {
  AchToCheck = 'ach_to_check',
}

export enum SelectVendorType {
  LOCAL = 'local',
  DIRECTORY = 'directory',
}

export enum DirectoryType {
  Biller = 'biller',
  Business = 'business',
}

export enum FastACHReviewAndConfirmEntryPoint {
  LinkAsEPLocatedInisdeTheH2Row = 'link-as-ep-located-Inisde-the-h2-row',
  LateBadgeNextToDeliveryDate = 'late-badge-next-to-delivery-date',
  ControlGroup = 'control-group',
}

export enum FailedFinancingPaymentType {
  failedToDeliverAch = 'financingFailedToDeliverAch',
  failedToDeliverCheck = 'financingFailedToDeliverCheck',
  canceledInstallmentsAch = 'financingCanceledInstallmentsAch',
  canceledInstallmentsCheck = 'financingCanceledInstallmentsCheck',
}

export enum BusinessVerificationStatus {
  PENDING_TO_CREATE = 'pending_to_create',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
  OPT_OUT = 'opt_out',
  NOT_REGISTERED = 'not_registered',
}

export const MANAGED_DELIVERY_TYPES = [DeliveryType.ACH, DeliveryType.VIRTUAL_CARD];

export const NO_PASSWORD_ERROR_CODES = ['ATH33', 'ATH34'];

export enum AuthErrorCodes {
  ERR_USER_NOT_VERIFIED = 'ATH28',
}

export enum BillingItemTypeEnum {
  ACH = 'ach',
  CHECK = 'check',
  CREDIT = 'credit',
  DEBIT = 'debit',
  EXPEDITED_ACH = 'expedited-ach',
  EXPEDITED_ACH_VENDOR = 'expedited-ach-vendor',
  EXPRESS_CHECK = 'express-check',
  OVERNIGHT_CHECK = 'overnight-check',
  PUSH_TO_DEBIT = 'push-to-debit',
  INTERNATIONAL = 'international',
  ACH_TO_CHECK = 'ach-to-check',
  RTP = 'rtp',
  RTP_ORGANIZATION = 'rtp-organization',
  INTERNATIONAL_FX = 'international-fx',
  DOMESTIC_WIRE = 'domestic-wire',
  AR_PROCESSING_FEE = 'ar-processing-fee',
}

export enum UserOrganizationPermissionEnum {
  BLOCKED = 'blocked',
  READONLY = 'readonly',
  FULL = 'full',
}
