import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { SideBySideLayoutPage } from 'src/components/layout/SideBySideLayoutPage';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import customersStore from 'src/modules/customers/customers-store';
import invoiceStore from 'src/modules/invoices/invoices-store';
import { BatchUploadContacts } from 'src/pages/contacts/create/BatchUploadContacts';
import { InvoiceFileUpload } from 'src/pages/get-paid/create/components/InvoiceFileUpload';
import { getOrgId } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { devices } from 'src/theme/appDevices';
import { useForm } from 'src/ui/form';
import { ContactsTab, Currency, NotificationVariant } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import useBatchCustomerUpload from '../hooks/useBatchCustomerUpload';
import InvoiceDetailsForm from './components/form/InvoiceDetailsForm';
import { PageProps } from './CreateInvoiceRouter';
import {
  getCustomerEmailById,
  getCustomerOptions,
  getFileInvoiceModel,
  getPreDefinedCustomer,
  isDuplicateInvoiceError,
  isSubmissionValidationError,
} from './utils';

type Props = PageProps & {
  onShareInvoice: (id: string) => void;
  onGoToSelectedItem: (id: string) => void;
  onCreateManualInvoice: () => void;
};

export const NewFileInvoicePage = ({ onExit, onShareInvoice, onGoToSelectedItem, onCreateManualInvoice }: Props) => {
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const [submitType, setSubmitType] = useState(null);
  const createStatus = useSelector(invoiceStore.selectors.create.status());
  const paymentRequest = useSelector(invoiceStore.selectors.fetch.byId(createStatus?.id));
  const isFileUploading = useSelector(invoiceStore.selectors.fileUpload.loading);
  const isFileUploadError = useSelector(invoiceStore.selectors.fileUpload.error);
  const billData = useSelector(invoiceStore.selectors.fileUpload.billData);
  const {
    fileData: { fileStorageUrl, filePreviewUrls, fileId },
  } = useSelector(invoiceStore.selectors.fileUpload.status);
  const contacts = useSelector((state) => customersStore.selectors.list.value(state, { orgId }));
  const contactList = useMemo(() => getCustomerOptions(contacts), [contacts]);
  const existingCustomer = contactList.find((c) => c.label === billData.customerNameValue);
  const contactOptions = useMemo(() => {
    const preDefinedCustomer = existingCustomer ? [] : getPreDefinedCustomer(billData.customerNameValue);

    return [...contactList, ...preDefinedCustomer];
  }, [contactList, existingCustomer, billData.customerNameValue]);
  const model = useMemo(() => getFileInvoiceModel(billData, existingCustomer), [billData, existingCustomer]);
  const invoiceActions = useStoreActions(invoiceStore);
  const customerActions = useStoreActions(customersStore);
  const fetchCustomersList = useCallback(() => {
    customerActions.list({ orgId });
  }, [customerActions, orgId]);
  const { customerCsvFile, onUploadBatchCustomer, onDoneBatchCustomer, onExitBatchCustomer } = useBatchCustomerUpload(
    fetchCustomersList
  );

  const onSubmit = async (invoice) => {
    const { dueDate, invoiceNumber, customerId, totalAmount, customerName } = invoice;
    const totalAmountValue = convertCurrencyToNumber(totalAmount);
    const customerEmail = getCustomerEmailById(contacts, customerId.value);
    const data = {
      totalAmount: totalAmountValue,
      dueDate,
      customerId,
      invoiceNumber,
      customerName,
      customerEmail,
      customerNote: '',
      files: [fileId],
      currency: Currency.USD,
    };
    try {
      await invoiceActions.create({ orgId, ...data });
    } catch (err) {
      if (isSubmissionValidationError(err)) {
        throw err;
      }

      // api error handling
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: isDuplicateInvoiceError(err) ? 'getPaid.new.create.error.duplicateInvoiceNumber' : 'serverErrors.ERR',
      });
    }
  };

  useEffect(() => {
    if (paymentRequest) {
      if (submitType === 'saveAndSent') {
        onShareInvoice(paymentRequest.id);
      } else {
        onGoToSelectedItem(paymentRequest.id);
      }
    }
  }, [paymentRequest, submitType, orgId]);

  const onSelectFile = async (file: File) => {
    await invoiceActions.fileUpload({ orgId, file });
  };

  const onCancelOrRemoveFile = useCallback(() => {
    // using dispatch here because this slice CreateSlice that doesn't have a dispatcher
    dispatch(invoiceStore.actions.clearFileUpload.clear());
  }, [dispatch]);

  const [invoiceMV, { submit }] = useForm(model, {
    submit: onSubmit,
  });

  useEffect(() => {
    fetchCustomersList();

    return onCancelOrRemoveFile;
  }, [fetchCustomersList, onCancelOrRemoveFile]);

  if (customerCsvFile) {
    return (
      <BatchUploadContacts
        file={customerCsvFile}
        type={ContactsTab.CUSTOMERS}
        goExit={onExitBatchCustomer}
        onPrev={onExitBatchCustomer}
        onNext={onDoneBatchCustomer}
      />
    );
  }

  const rightContent = (
    <FormContainer>
      <InvoiceDetailsForm
        submit={submit}
        cancel={onCancelOrRemoveFile}
        setSubmitType={setSubmitType}
        submitType={submitType}
        model={invoiceMV}
        contactOptions={contactOptions}
        createStatus={createStatus?.loading}
        onBatchCustomerUpload={onUploadBatchCustomer}
      />
    </FormContainer>
  );
  const leftContent = (
    <UploadArea fileStorageUrl={fileStorageUrl}>
      <InvoiceFileUpload
        onCancelUploadFile={onCancelOrRemoveFile}
        onRemoveFile={onCancelOrRemoveFile}
        onSelectFile={onSelectFile}
        isUploading={isFileUploading}
        isUploadError={isFileUploadError}
        fileStorageUrl={fileStorageUrl}
        filePreviewUrls={filePreviewUrls}
        goManual={onCreateManualInvoice}
      />
    </UploadArea>
  );

  return <SideBySideLayoutPage rightContent={rightContent} leftContent={leftContent} onExit={onExit} />;
};

const FormContainer = styled.div<{ manually?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  padding: 0 6rem;
  max-width: ${(props) => (props.manually ? '45rem' : '40rem')};

  @media ${devices.mobile} {
    padding: 2.4rem 0 0;
  }
`;

const UploadArea = styled.div<{
  fileStorageUrl?: string;
  isUploading?: boolean;
}>`
  height: 80vh;
  border: ${(props) => (props.fileStorageUrl ? '0.2rem dashed' : '0.1rem solid')};
  border-color: ${(props) => props.theme.colors.border.darkGrey};
  border-radius: 0.6rem;
  max-width: 60rem;
  width: 100%;
  margin: auto;
  position: relative;

  @media ${devices.nonDesktop} {
    height: 100%;
  }

  @media ${devices.mobile} {
    ${(props) =>
      props.isUploading &&
      css`
        border: none;
      `}

    ${(props) =>
      !props.isUploading &&
      css`
        height: unset;
        margin: 2.2rem 2.2rem 3.2rem 2.2rem;
      `}
  }
`;
