// eslint-disable-next-line no-restricted-imports
import { ChakraProvider } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { initContextHeader } from 'src/services/api/api';
import DefaultSite from 'src/sites/defaultSite';
import { mcbSite } from 'src/sites/mcbSite';
import { Site } from 'src/sites/site';
import { GlobalStyle } from 'src/theme/global-theme';
import { SITE_NAME } from './constants';

const SiteContext = React.createContext<Site>(null as any);

const SITES: Record<string, Site> = {
  [SITE_NAME.APP.DEFAULT]: DefaultSite,
  [SITE_NAME.APP.ALPHA]: DefaultSite,
  [SITE_NAME.APP.DEV]: DefaultSite,
  [SITE_NAME.MCB.DEFAULT]: mcbSite,
  mcb: mcbSite,
};

const DEFAULT_SITE_NAME = SITE_NAME.APP.DEFAULT;
const SITE_OVERRIDE = process.env.REACT_APP_SITE;

export function getSiteFromUrl(href: string) {
  const uri = new URL(href);
  const { host } = uri;
  const siteConfigParam: any = uri.searchParams.get('siteConfig');

  if (SITES[siteConfigParam]) {
    return SITES[siteConfigParam];
  } else if (SITES[host]) {
    return SITES[host];
  } else if (SITE_OVERRIDE && SITES[SITE_OVERRIDE.toLowerCase()]) {
    return SITES[SITE_OVERRIDE.toLowerCase()];
  }

  return SITES[DEFAULT_SITE_NAME];
}

function getImgPaths(includedPaths: Array<string>) {
  const requireContext = require.context('../images', true, /\.(png|jpe?g|svg|gif)$/);

  return requireContext
    .keys()
    .filter((filePath) => includedPaths.some((path) => filePath.includes(path)))
    .map(requireContext) as string[];
}

function preloadImages(paths) {
  const imgPaths = process.env.NODE_ENV === 'test' ? [] : getImgPaths(paths);

  imgPaths.forEach((imgPath) => {
    const img = new Image();

    img.src = imgPath;
  });
}
type SiteProviderProps = {
  site: Site;
  children: React.ReactNode;
};

const setSiteContextName = (site) => {
  initContextHeader(site);
};

export function SiteProvider({ site, children }: SiteProviderProps) {
  const firstRender = useRef<boolean>();

  if (!firstRender.current) {
    firstRender.current = true;
    setSiteContextName(getSiteFromUrl(window.location.href).name);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    preloadImages(site.preloadImages);
  }, [site]);

  return (
    <SiteContext.Provider value={site}>
      <ThemeProvider theme={site.theme}>
        <ChakraProvider resetCSS={false} theme={site.chakraTheme}>
          {children}
        </ChakraProvider>
        <GlobalStyle />
      </ThemeProvider>
    </SiteContext.Provider>
  );
}
export { SiteContext };
