import { ChangeEvent, memo, useEffect, useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import { useExtraAnalyticsProps } from 'src/analytics/useExtraAnalyticsProps';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { MILink } from 'src/components/common/MILink';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import * as WizardElements from 'src/components/layout/WizardElements';
import { OrSeparator } from 'src/core/components/orSeparator';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import sessionExpiredImage from 'src/images/general/session-expired.svg';
import { LoginWithGoogleButton } from 'src/pages/auth/components/login-with-google-button';
import { InputContainer } from 'src/pages/auth/components/penny-like/InputContainer';
import { CardLayout } from 'src/pages/auth/components/register-page-experiment/CardLayout';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';
import { devices } from 'src/theme/appDevices';
import { NO_PASSWORD_ERROR_CODES, NotificationCardTypes } from 'src/utils/consts';
import { CredentialsType, ToNavigationType } from 'src/utils/types';
import { useSignInScreenName } from '../hooks/useSignInScreenName';
import { MfaCodeVerificationState } from '../multi-factor-authentication/consts';
import { LoginWithIntuitButton } from './login-with-intuit-button';

type Props = {
  onLogin: (credentials: CredentialsType) => void;
  goLinkQuickbooks?: () => void;
  loginWithGoogle?: () => void;
  goRegister: () => void;
  goResetPassword: ToNavigationType | (() => void);
  isLoading?: boolean;
  errorCode?: string | null;
  validationErrors: Record<string, any>;
  onRequestPassword: (email: string) => void;
  isPasswordRequestSucceeded: boolean;
  errorCodeRequestPassword?: string;
};

export const LoginPage = memo(
  ({
    onLogin,
    goLinkQuickbooks,
    loginWithGoogle,
    goRegister,
    goResetPassword,
    isLoading,
    errorCode,
    validationErrors,
    onRequestPassword,
    isPasswordRequestSucceeded,
    errorCodeRequestPassword,
  }: Props) => {
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { isSessionExpired, screenName } = useSignInScreenName();
    const isMfaError = errorCode === MfaCodeVerificationState.DENIED;
    const eventPage = 'sign-in';
    const analyticsProperties = {
      PageName: eventPage,
      Flow: eventPage,
      Intent: eventPage,
      EntryPoint: 'home-page',
      EligabilityType: isMfaError ? 'too-many-failed-attempts' : null,
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        buttonRef?.current?.click();
      }
    };

    useEffect(() => {
      analytics.track('Connection', 'View', analyticsProperties);

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const openSupportChat = () => {
      analytics.track('Connection', 'Click', { ...analyticsProperties, Cta: 'chat-with-us', ErrorType: errorCode });
      intercomService.show();
    };

    const handleGoRegister = () => {
      analytics.track('Connection', 'Click', { ...analyticsProperties, Cta: 'sign-up' });
      goRegister();
    };

    const handleGoLinkQuickbooks = () => {
      if (goLinkQuickbooks) {
        analytics.track('Connection', 'Click', { ...analyticsProperties, Cta: 'sign-in-with-intuit' });
        goLinkQuickbooks();
      }
    };

    const handleLoginWithGoogle = () => {
      if (loginWithGoogle) {
        analytics.track('Connection', 'Click', { ...analyticsProperties, Cta: 'connect-in-with-google' });
        loginWithGoogle();
      }
    };

    const handleLogin = () => {
      onLogin({
        email,
        password,
      });
    };

    const handleRequestPassword = () => {
      onRequestPassword(email);
    };

    useExtraAnalyticsProps(eventPage, { screenName, ...analyticsProperties });

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => setPassword(event.currentTarget.value);
    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value);

    const emailPasswordInputs = !isMfaError ? (
      <>
        <PrivateDataContainer>
          <TextField
            id="email"
            value={email}
            label="auth.login.email"
            onChange={handleEmailChange}
            autoFocus
            autoComplete="username email"
            type="email"
            errorMessage={validationErrors?.email}
            isRequired
          />
        </PrivateDataContainer>
        <PrivateDataContainer>
          <PasswordField
            id="password"
            value={password}
            onChange={handlePasswordChange}
            label="auth.login.password"
            errorMessage={validationErrors?.password}
            helperText="auth.signInCondensed.forgotPassword"
            helperTextValues={{
              link: () => <MILink to={goResetPassword} label="auth.login.forgotPassword" preserveState plain />,
            }}
            isRequired
          />
        </PrivateDataContainer>
      </>
    ) : null;

    const registerLayoutData = () => {
      if (isSessionExpired) {
        return {
          title: 'auth.signIn.expiredTitle',
          hideLogo: true,
          iconSrc: sessionExpiredImage,
        };
      }

      return {
        title: 'auth.signIn.title',
        textValues: {
          link: (label) => <StyledMIInlineLink onClick={handleGoRegister} label={label} testId={`link-${label}`} />,
        },
        text: 'auth.signIn.subtitle',
      };
    };

    const orLine = (
      <Box my={4}>
        <OrSeparator color="grey.500" />
      </Box>
    );

    const alternativeLoginButtons = (
      <AlternativeLoginButtons
        goLinkQuickbooks={goLinkQuickbooks ? handleGoLinkQuickbooks : undefined}
        loginWithGoogle={loginWithGoogle ? handleLoginWithGoogle : undefined}
      />
    );

    const isRequestPasswordError = !!errorCodeRequestPassword;
    const isNoPasswordError =
      !isRequestPasswordError && !isPasswordRequestSucceeded && NO_PASSWORD_ERROR_CODES.includes(errorCode as string);
    const isGeneralError = !isNoPasswordError && !isRequestPasswordError && !isPasswordRequestSucceeded && !!errorCode;

    const serverErrors = (
      <>
        {isNoPasswordError && (
          <NotificationCard
            type={NotificationCardTypes.ERROR}
            subtitle={{
              label: `server.${errorCode}`,
              values: {
                continue: (
                  <MILink
                    testId="no-password-continue"
                    to={handleRequestPassword}
                    label="server.NO_PASSWORD_CONTINUE"
                    plain
                  />
                ),
              },
            }}
          />
        )}
        {isGeneralError && (
          <NotificationCard
            type={NotificationCardTypes.ERROR}
            title={{ label: isMfaError ? `guests.mfaVerification.notification.deniedLogIn.title` : undefined }}
            subtitle={{
              label: isMfaError ? `guests.mfaVerification.notification.deniedLogIn.subtitle` : `server.${errorCode}`,
              values: {
                chatWithUs: (
                  <Box
                    data-testid="notification-support-link"
                    as="a"
                    cursor="pointer"
                    onClick={openSupportChat}
                    textDecor="underline"
                  >
                    <MIFormattedText label="guests.mfaVerification.notification.chatWithUs" />
                  </Box>
                ),
              },
            }}
          />
        )}
        {isPasswordRequestSucceeded && (
          <NotificationCard
            type={NotificationCardTypes.SUCCESS}
            subtitle={{
              label: 'auth.requestPassword.successNotification',
            }}
          />
        )}
        {isRequestPasswordError && (
          <NotificationCard
            type={NotificationCardTypes.ERROR}
            subtitle={{
              label: `server.${errorCodeRequestPassword}`,
            }}
          />
        )}
      </>
    );

    const layoutData = registerLayoutData();

    return (
      <CardLayout testId="login-page" {...layoutData}>
        {serverErrors}
        {isMfaError ? null : (
          <>
            <InputContainer>{emailPasswordInputs}</InputContainer>
            <Button
              label="auth.signIn.buttonLabel"
              onClick={handleLogin}
              size={ButtonSizes.lg}
              isFullWidth
              isLoading={isLoading}
              ref={buttonRef}
            />
            {orLine}
          </>
        )}
        {alternativeLoginButtons}
        <Box fontSize="14px" lineHeight="20px" marginTop="24px" textAlign="center" color="#4A505B">
          <MIFormattedText label="auth.signInCondensed.footerLabel" values={layoutData.textValues} />
        </Box>
      </CardLayout>
    );
  }
);

const AlternativeLoginButtons = ({
  loginWithGoogle,
  goLinkQuickbooks,
}: Pick<Props, 'loginWithGoogle' | 'goLinkQuickbooks'>) => {
  const wizardButtonContainerStyles = {
    margin: 0,

    button: {
      marginBottom: 0,
    },
  };

  const google = loginWithGoogle ? (
    <WizardElements.WizardButtonContainer css={wizardButtonContainerStyles} fullWidthCTA={false}>
      <LoginWithGoogleButton onClick={loginWithGoogle} />
    </WizardElements.WizardButtonContainer>
  ) : null;

  const intuit = goLinkQuickbooks ? (
    <WizardElements.WizardButtonContainer css={wizardButtonContainerStyles} fullWidthCTA={false}>
      <LoginWithIntuitButton onClick={goLinkQuickbooks} />
      <Box textStyle="body3" color="grey.700" py={2} textAlign="center">
        <MIFormattedText label="auth.intuitExplainer" />
      </Box>
    </WizardElements.WizardButtonContainer>
  ) : null;

  return (
    <>
      <Box mb={4}>{google}</Box>
      <Box mb={0}>{intuit}</Box>
    </>
  );
};

const NotificationCard = styled(MINotificationCard)`
  margin: 3rem 0 4rem;
  @media ${devices.mobile} {
    margin: 1rem 0 2rem;
  }
`;
const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: inherit;
  line-height: inherit;
  height: auto;
`;
