/* eslint-disable no-template-curly-in-string,max-len,no-unused-vars */
import { PAY_BILL_MAPPING as amexVendorAddressModal } from 'src/components/funding-source/amex/event-mapping-amex-address';
import {
  BILL_MAPPING as amexIndustryModal,
  GUEST_MAPPING as amexGuestEventMapping,
} from 'src/components/funding-source/amex/event-mapping-amex-industry';
import { GUEST_MAPPING as visaGuestEventMapping } from 'src/components/funding-source/visa/event-mapping-visa-industry';
import { authEventsMapping } from 'src/pages/auth/events-mapping';
import { MAPPING as failedPaymentSelectDMPageMapping } from 'src/pages/bill/pay/analytics/fast-payment-event-mapping';
import { financingEventMapping } from 'src/pages/bill/pay/analytics/financing-event-mapping';
import { scannedInvoicesEventMapping } from 'src/pages/bill/pay/analytics/scanned-invoices-event-mapping';
import { MAPPING as undepositedCheckFastPaymentPageMapping } from 'src/pages/bill/pay/analytics/undeposited-check-fast-payment-event-mapping';
import { MAPPING as undepositedCheckPaymentSelectDMPageMapping } from 'src/pages/bill/pay/analytics/undeposited-check-select-dm-event-mapping';
import { selfServeRefundEventMapping } from 'src/pages/bill/pay/components/RequestRefundWizard/analytics/self-serve-refund-event-mapping';
import companiesEventsMapping from 'src/pages/companies/events-mapping';
import contactsEventsMapping from 'src/pages/contacts/events-mapping';
import getPaidEventsMapping from 'src/pages/get-paid/event-mapping';
import { getProEventsMapping } from 'src/pages/get-pro/analytics/event-mapping';
import { melioMeLocations } from 'src/pages/meliome/locations';
import onboardingAddVendorEventsMapping from 'src/pages/onboarding/add-vendor/events-mapping';
import onboardingEventsMapping from 'src/pages/onboarding/company-info/events-mapping';
import defineRelationshipEventsMapping from 'src/pages/onboarding/define-relationship/events-mapping';
import fundingSourceEventMapping from 'src/pages/onboarding/funding-sources/event-mapping';
import { msnClaimAccountEventsMapping } from 'src/pages/onboarding/msn-registration/claim-account/events-mapping';
import { msnPublicFormOnboardingEventMapping } from 'src/pages/onboarding/msn-registration/public-form/events-mapping';
import vendorOnboardingEventsMapping from 'src/pages/onboarding/vendor-company-info/events-mapping';
import quickbookMultiOrgOnboardingEventsMapping from 'src/pages/quickbook-multi-org-onboarding/events-mapping';
import receivingMethodsEventMapping from 'src/pages/receiving-method/event-mapping';
import { MAPPING as regularBatchPaymentsMapping } from 'src/pages/regular-batch-payments/analytics/event-mapping';
import { MAPPING as regularBatchPaymentsSelectionMapping } from 'src/pages/regular-batch-payments/analytics/selection-event-mapping';
import { MAPPING as approvalWorkflowsEventMapping } from 'src/pages/settings/components/ApprovalWorkflows/event-mapping';
import { MAPPING as emailNotificationsEventMapping } from 'src/pages/settings/components/EmailNotificationsPage/event-mapping';
import accountingSoftwareSyncEventsMapping from 'src/pages/settings/event-mapping';
import { MAPPING as teamEventMapping } from 'src/pages/team/analytics/event-mapping';
import vendorsEventsMapping from 'src/pages/vendor/event-mapping';
import { msnSalesFormEventMapping } from 'src/pages/vendor/msn-candidates/events-mapping';
import { vendorUpgradePaymentEventMapping } from 'src/pages/vendor/upgrade-payment/vendor-upgrade-payment-event-mapping';
import { EventMappingName } from 'src/utils/consts';
import { createPageViewEventsFromLocations, mapApiSlice } from './event-mapping-utils';
import { EventMappingsTree } from './types';

const billPayMemoPageProperties = {
  vendorId: 'vendorId',
  screenName: 'screenName',
  partialBillId: 'partialBillId',
};

const DEFAULT_MAPPING_NAME = EventMappingName.DEFAULT;

const MAPPINGS: EventMappingsTree = {
  '/orgs/*/settings/payment-methods': {
    'toggle-actions-menu': ['settings-payment-methods', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'add-payment-method-label': ['settings-payment-methods', 'add-payment-method-label', { fundingSourceId: 'id' }],
    'add-payment-method-label-success': [
      'settings-payment-methods',
      'add-payment-method-label-successful',
      { fundingSourceId: 'id', fundingSourceLabel: 'label' },
    ],
  },
  '/register/approve-amount': {
    'page.view': ['auth', 'register-set-amount'],
    'auth.register.setAmount.checkboxText': ['auth', 'register.setAmount.disable'],
  },
  '/register/funding-source': {
    'page.view': ['auth', 'register-funding-source'],
    'auth.register.addFundingSource.skip': ['register-funding-source', 'register.addFundingSource.skip'],
  },
  '/register/profile-picture': {
    'page.view': ['team-member-onboarding', 'add-profile-picture'],
    'auth.addProfilePicture.buttons.save': ['team-member-onboarding_add-profile-picture', 'save'],
    'auth.addProfilePicture.buttons.skip': ['team-member-onboarding_add-profile-picture', 'skip'],
    'user-picture-clicked': ['team-member-onboarding', 'user-picture-clicked'],
  },
  '/register/from-invitation/*': {
    'page.view': ['auth', 'register-from-invitation'],
    'teamManagement.beforeMergeExistingFirm.buttons.cancel': ['auth-merge-multi-org-into-firm', 'cancel_clicked'],
    'teamManagement.beforeMergeExistingFirm.buttons.submit': ['auth-merge-multi-org-into-firm', 'confirm_clicked'],
  },
  '/orgs/*/settings/receiving-methods': {
    'toggle-actions-menu': ['settings-receiving-methods', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/orgs/*/settings/vendors/*': {
    'toggle-actions-menu': ['settings-vendors-delivery-methods', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'qbo-view-vendors-actions': ['settings-vendors', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/orgs/*/settings/account': {
    '[PROFILE] PASSWORD_CHANGE_REQUEST': ['reset', 'password-continue'],
    '[PROFILE] PASSWORD_CHANGE_FAILURE': ['reset', 'password-validation-error', { error: 'error' }],
    '[PROFILE] PASSWORD_CHANGE_SUCCESS': ['reset', 'password-validation-success'],
    '[USER] UPDATE_USER_PREFERENCE': ['settings-account-information', 'update-${id}', { isOn: 'value' }],
    '[USER] UPDATE_USER_PREFERENCE_SUCCESS': ['settings-account-information', 'update-${id}-success'],
    '[USER] UPDATE_USER_PREFERENCE_FAILURE': ['settings-account-information', 'update-${id}-failed'],
    '[USERS] UPDATE_REQUEST': ['settings-account-information', 'update-details', { payload: 'payload' }],
    '[USERS] UPDATE_SUCCESS': ['settings-account-information', 'update-details-success'],
    '[USERS] UPDATE_FAILURE': ['settings-account-information', 'update-details-failed', { error: 'error' }],
    'profile.changePassword.edit': ['settings-account-information', 'change-password-menu'],
    'profile.details.edit': ['settings-account-information', 'change-password-menu'],
    'general.done': ['settings-account-information', 'save-button'],
    'general.cancel': ['settings-account-information', 'cancel-button'],
    'user-picture-clicked': ['settings-account-information', 'user-picture-clicked'],
    'user-picture-deleted': ['settings-account-information', 'user-picture-deleted'],
  },
  '/orgs/*/settings/company': {
    'get-pro-enabled': ['settings-company', 'turn-on-pro'],
    'get-pro-disabled': ['settings-company', 'turn-off-pro'],
    'copy-link': ['settings-company', 'link-copied'],
    'copy-link-action': ['settings-company', 'meliome-link-clicked'],
    'copy-email-link': ['settings-company', 'email-link-copied'],
    'copy-email-link-action': ['settings-company', 'email-link-action-clicked'],
    'payments-csv-report.clicked': ['settings-company', 'payments-csv-report-clicked'],
    'payments-csv-report.downloaded': [
      'settings-company',
      'payments-csv-report-downloaded',
      {
        startDate: 'startDate',
        endDate: 'endDate',
        countPayments: 'countPayments',
        includeScheduledPayments: 'includeScheduledPayments',
      },
    ],
  },
  '/orgs/*/settings/users': {
    'toggle-actions-menu': ['user-summary', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'settings.manageUsers.inviteUser': ['manage-users', 'invite-user'],
    'invite-user-submit': [
      'manage-users',
      'invite-user-submit',
      {
        inviteeApprovalAmountThreshold: 'approvalAmountThreshold',
        inviteeEmail: 'email',
        inviteeFirstName: 'firstName',
        inviteeLastName: 'lastName',
        inviteeRequireApproval: 'requireApproval',
        inviteeRole: 'role',
      },
    ],
    'invitation.action.cancel': ['manage-users', 'delete-invite-menu'],
    'invitation.action.teamManagement.cancel': ['manage-users', 'delete-invite-menu'],
    'invitation.action.resend': ['manage-users', 'resend-invite-menu'],
    'invitation.action.teamManagement.resend': ['manage-users', 'resend-invite-menu'],
    'user.action.edit': ['manage-users', 'edit-user-menu'],
    'user.action.teamManagement.editContributor': ['manage-users', 'edit-user-menu'],
    'user.action.teamManagement.editMember': ['manage-users', 'edit-user-menu'],
    'user.action.delete': ['manage-users', 'delete-user-menu'],
    'user.action.teamManagement.removeFromClient': ['manage-users', 'delete-user-menu'],
    'option-selected-invite-role': ['manage-users', 'create-invitation-change-role', { role: 'option.id' }],
    'option-selected-user-role': ['manage-users', 'edit-user-change-role', { role: 'option.id' }],
    ...mapApiSlice('[INVITATIONS] CREATE', 'manage-users', 'invite-user', {
      request: { payload: 'payload' },
      success: { user: 'payload.email' },
    }),
    ...mapApiSlice('[INVITATIONS] UPDATE', 'manage-users', 'resend-invite'),
    ...mapApiSlice('[INVITATIONS] DELETE', 'manage-users', 'delete-invite'),
    ...mapApiSlice('[USERS] USER_MANAGEMENT_UPDATE', 'manage-users', 'update-user'),
    ...mapApiSlice('[USERS] DELETE', 'manage-users', 'delete-user'),
    ...mapApiSlice('[USERS] USER_MANAGEMENT_CHANGE_OWNER', 'manage-users', 'change-owner'),
    'user.editDialog.cancel': ['manage-users', 'cancel-change-owner'],
    editUser: ['manage-users', 'edit-user-modal', { toggle: 'toggle' }],
    deleteUser: ['manage-users', 'delete-user-modal', { toggle: 'toggle' }],
    changeOwner: ['manage-users', 'change-owner-modal', { toggle: 'toggle' }],
  },
  '/orgs/*/settings/support': {
    'toggle-faq-item': ['support', 'toggle-faq-item', { id: 'id' }],
    'email-clicked': ['support', 'email-clicked'],
    'settings.support.startChat': ['support', 'start-chat-clicked'],
    'all-help-topics-clicked': ['support', 'all-help-topics-clicked'],
  },
  '/orgs/*/bills': {
    'schedule.payment': ['bills', 'dashboard-schedule-payment-clicked'],
    'fab.action': ['bills', 'dashboard-fab-clicked'],
    'fab.newFab.bills': ['fab', 'new-payment-button'],
    'settings.view': ['bills', 'dashboard-settings-clicked'],
    'faq.view': ['bills', 'dashboard-faq-clicked'],
    'page.view': ['bills', 'dashboard-bills-list'],
    'bills.actions.decline': ['bills', 'decline-payment-button-clicked'],
    ...mapApiSlice('[PAYMENTS] PAYMENT_APPROVED', 'bills', 'payment_approved'),
    ...mapApiSlice('[PAYMENTS] PAYMENT_DECLINED', 'bills', 'payment_declined', {
      request: { reason: 'payload.reason' },
    }),
    'modal-close-decline-payment-modal': ['bills', 'decline-payment-modal-closed'],
    'toggle-actions-menu': ['bills', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'bills-payment': ['bills-payment', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'slide-change': [
      'product-education',
      'slide-change',
      { originSlide: 'originSlide', destinationSlide: 'destinationSlide' },
    ],
    'display-slide-howItWorks': ['product-education', 'tutorial-slide-howItWorks'],
    'bills.newEmptyState.productEducation.howItWorks.action': ['product-education', 'watch-video'],
    'modal-close-howItWorks': ['product-education', 'video-closed'],
    'display-slide-connectQB': ['product-education', 'tutorial-slide-connectQB'],
    'bills.newEmptyState.productEducation.connectQB.action': ['product-education', 'connectQB-start'],
    'display-slide-creditCard': ['product-education', 'tutorial-slide-creditCard'],
    'bills.newEmptyState.productEducation.creditCard.action': ['product-education', 'add-credit-card-start'],
    'bills.actions.trackDelivery': ['check', 'tracking-delivery'],
    'bills.newEmptyState.ctaButtonText': ['bills', 'add-your-first-bill-button'],
    'mark-as-paid-modal': ['bills', 'mark-as-paid-modal', { toggle: 'toggle' }],
    'option-changed-intuitAccountId': ['bills', 'mark-as-paid-modal-option-changed', { qboAccountId: 'option.value' }],
    'bills.form.markAsPaidDialog.dialogOkButtonText': ['bills', 'mark-as-paid-modal-submit'],
    ...regularBatchPaymentsSelectionMapping,
    'vendorDirectory.education.modalClose': ['billers-modal', 'billers-modal-closed'],
    'vendorDirectory.education.modalButton': ['billers-modal', 'billers-modal-on-new-bill'],
    'toggle-search-input': ['bills', 'toggle-search-input', { isOpen: 'isOpen' }],
    'search-in-bills': ['bills', 'search-enter', { searchValue: 'searchValue', tabName: 'tabName' }],
    Sorted: [
      'bills',
      'Sorted',
      {
        previousSorting: 'previousSorting',
        previousSortingDirection: 'previousSortingDirection',
        chosenSorting: 'chosenSorting',
        chosenSortingDirection: 'chosenSortingDirection',
        tabName: 'tabName',
      },
    ],
    Filtered: [
      'bills',
      'Filtered',
      {
        tabName: 'tabName',
        previousFilters: 'previousFilters',
        chosenFilters: 'chosenFilters',
      },
    ],
    'request-refund': [
      'bill-failed',
      'refund-payment',
      { paymentId: 'paymentId', billId: 'billId', isSelfServeEligible: 'isSelfServeEligible' },
    ],
    'refund-status': [
      'refund-payment',
      'viewed-refund-status',
      { paymentId: 'paymentId', billId: 'billId', status: 'status' },
    ],
    'refund-link': [
      'bills',
      'failed-payment-refund-suggestion',
      { paymentId: 'paymentId', billId: 'billId', isSelfServeEligible: 'isSelfServeEligible' },
    ],
    'chat-with-support': ['refund-payment', 'chat-with-support', { paymentId: 'paymentId', billId: 'billId' }],
    'talk-to-support': ['refund-payment', 'talk-to-support', { paymentId: 'paymentId', billId: 'billId' }],
    'delete-bill': [
      'bills',
      'delete-bill',
      { paymentId: 'paymentId', billId: 'billId', paymentStatus: 'paymentStatus' },
    ],
    'delete-bill-confirmed': [
      'bills',
      'delete-bill-confirmed',
      { paymentId: 'paymentId', billId: 'billId', paymentStatus: 'paymentStatus' },
    ],
    'delete-bill-success': [
      'bills',
      'delete-bill-success',
      { paymentId: 'paymentId', billId: 'billId', paymentStatus: 'paymentStatus' },
    ],
    'delete-bill-canceled': [
      'bills',
      'delete-bill-canceled',
      { paymentId: 'paymentId', billId: 'billId', paymentStatus: 'paymentStatus' },
    ],
  },
  '/orgs/*/bills/new': {
    'page.view': ['bills', 'new-bill'],
    'bills.new.saveAndAdd': ['bill-create', 'save'],
    'bills.new.saveAndClose': ['bill-create', 'save-and-close'],
  },
  '/orgs/*/bills/new/create-options': {
    'page.view': ['bills', 'new-bill-create-options'],
  },
  '/orgs/*/bills/*/pay/funding': {
    'payment-on-focus-amount': ['pay-bill', 'focus-payment-amount', { amount: 'amount' }],
    ...amexIndustryModal,
    ...amexVendorAddressModal,
  },
  '/orgs/*/bills/recurring/pay/funding': {
    'page.view': ['pay-bill', 'recurring-add-funding'],
  },
  '/orgs/*/bills/*/pay/delivery-method': {
    'page.view': ['pay-bill', 'add-delivery'],
  },
  '/orgs/*/bills/*/payment/*/edit/delivery-method': {
    'page.view': ['pay-bill', 'edit-delivery-method'],
  },
  '/orgs/*/bills/recurring/pay/delivery-method': {
    'page.view': ['pay-bill', 'recurring-add-delivery'],
  },
  '/orgs/*/bills/*/pay/date': {
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
  },
  '/orgs/*/bills/*/payment/*/edit/date': {
    'page.view': ['pay-bill', 'edit-date'],
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
  },
  '/orgs/*/bills/pay/recurring/memo': {
    'page.view': ['pay-bill', 'recurring-add-memo'],
    'form-validation-error': ['pay-bill', 'validation-error', { validationsErrors: 'validationErrors' }],
    'vendor-email-save-success': [
      'pay-bill',
      'vendor-email-save-success',
      {
        ...billPayMemoPageProperties,
        vendorEmail: 'vendorEmail',
      },
    ],
    'exposed-to-add-vendor-email-line': [
      'pay-bill',
      'exposed-to-add-vendor-email-line',
      {
        ...billPayMemoPageProperties,
      },
    ],
  },
  '/orgs/*/bills/*/pay/memo': {
    'page.view': ['pay-bill', 'memo'],
    'form-validation-error': ['pay-bill', 'validation-error', { validationsErrors: 'validationErrors' }],
    'vendor-email-save-success': [
      'pay-bill',
      'vendor-email-save-success',
      {
        ...billPayMemoPageProperties,
        vendorEmail: 'vendorEmail',
      },
    ],
    'exposed-to-add-vendor-email-line': [
      'pay-bill',
      'exposed-to-add-vendor-email-line',
      {
        ...billPayMemoPageProperties,
      },
    ],
  },
  '/orgs/*/bills/*/payment/*/edit/memo': {
    'page.view': ['pay-bill', 'edit-memo'],
    'form-validation-error': ['pay-bill', 'validation-error', { validationsErrors: 'validationErrors' }],
    'vendor-email-save-success': [
      'pay-bill',
      'vendor-email-save-success',
      {
        ...billPayMemoPageProperties,
        vendorEmail: 'vendorEmail',
      },
    ],
    'exposed-to-add-vendor-email-line': [
      'pay-bill',
      'exposed-to-add-vendor-email-line',
      {
        ...billPayMemoPageProperties,
      },
    ],
  },
  '/orgs/*/bills/*/payment/*/edit/resend-undeposited-check': {
    ...undepositedCheckPaymentSelectDMPageMapping,
  },
  '/orgs/*/bills/*/payment/*/edit/undeposited-check-fast-payment': {
    ...undepositedCheckFastPaymentPageMapping,
  },
  '/orgs/*/bills/*/payment/*/edit/fast-payment': {
    ...failedPaymentSelectDMPageMapping,
  },
  '/orgs/*/bills/recurring/pay/confirm': {
    'page.view': ['pay-bill', 'recurring-confirm'],
  },
  '/orgs/*/bills/*/pay/confirm': {
    ...mapApiSlice('[VENDORS] UPDATE', 'missing-account-number-modal', 'save', {
      success: {
        billerId: 'payload.deliveryMethods[0].rppsAccount.billerId',
        accountNumber: 'meta.identifier.accountIdentifier',
        vendorId: 'payload.id',
      },
    }),
  },
  '/orgs/*/bills/*/payment/*/edit/confirm': {
    'page.view': ['pay-bill', 'edit-confirm'],
  },
  '/orgs/*/bills/recurring/pay/success': {
    'page.view': ['pay-bill', 'recurring-success'],
  },
  '/orgs/*/bills/*/pay/success': {
    'page.view': ['pay-bill', 'success'],
    'go-to-dashboard': [
      'pay-bill-success',
      'go-to-online-bill-payment',
      { flow: 'flow', fromDashboard: 'fromDashboard' },
    ],
  },
  '/orgs/*/bills/*/pay/success-from-dashboard': {
    'page.view': ['pay-bill', 'success'],
    'go-to-dashboard': [
      'pay-bill-success',
      'go-to-online-bill-payment',
      { flow: 'flow', fromDashboard: 'fromDashboard' },
    ],
  },
  '/orgs/*/bills/recurring/pay/complete-legal-info': {
    'page.view': ['pay-bill', 'recurring-complete-legal-info'],
  },
  '/orgs/*/bills/*/pay/complete-legal-info': {
    'page.view': ['pay-bill', 'complete-legal-info'],
  },
  '/orgs/*/bills/contractors': {
    'page.view': ['pay-bill', 'contractor-page'],
  },
  '/login': {
    'auth.login.forgotPassword': ['auth', 'change-password'],
  },
  '/quickbooks/block/*': {
    'page.view': ['quickbooks', 'blocked-user'],
  },
  '/spend-management/login': {
    'page.view': ['spend-management', 'login'],
    'auth.signUp.title': ['spend-management-login', 'click-signup'],
    'auth.login.forgotPassword': ['spend-management-login', 'click-forgot-password'],
    'auth.regularRegister.userAgreement': ['spend-management-login', 'click-tos'],
    'auth.regularRegister.privacyPolicy': ['spend-management-login', 'click-privacy'],
  },
  '/spend-management/register': {
    'page.view': ['spend-management', 'signup'],
    'auth.signIn.title': ['spend-management-signup', 'click-login'],
    'auth.signUp.buttonLabel': ['spend-management-signup', 'click-continue'],
    'auth.regularRegister.userAgreement': ['spend-management-signup', 'click-tos'],
    'auth.regularRegister.privacyPolicy': ['spend-management-signup', 'click-privacy'],
    '[AUTH] REGULAR_REGISTRATION_REQUEST': ['spend-management-signup', 'continue-request'],
    '[AUTH] REGULAR_REGISTRATION_FAILURE': [
      'spend-management-signup',
      'continue-failed',
      { error: 'error', email: 'payload.email' },
    ],
    '[AUTH] REGULAR_REGISTRATION_VALIDATION_FAILURE': [
      'spend-management-signup',
      'validation-error',
      { validation: 'error.validationErrors' },
    ],
    '[AUTH] REGULAR_REGISTRATION_SUCCESS': ['spend-management-signup', 'continue-success'],
  },
  '/register': {
    'page.view': ['auth', 'register'],
    'auth.register.loginLink': ['register', 'back-to-login'],
    'go.bookkeeper-learn-more': ['register', 'bookkeeper-learn-more'],
    '[AUTH] REGULAR_REGISTRATION_REQUEST': ['register-with-email', 'request'],
    '[AUTH] REGULAR_REGISTRATION_FAILURE': ['register-with-email', 'error', { error: 'error', email: 'payload.email' }],
    '[AUTH] REGULAR_REGISTRATION_VALIDATION_FAILURE': [
      'register-with-email',
      'validation-error',
      { validation: 'error.validationErrors' },
    ],
    '[AUTH] REGULAR_REGISTRATION_SUCCESS': ['register-with-email', 'password-continue-success'],
  },
  '/register/accountant-type': {
    'page.view': ['auth', 'register-from-invitation-choose-accountant-type'],
    'progress.continue': ['register-from-invitation-choose-accountant-type', 'submit'],
    'select-category': ['auth', 'select-is-external-accountant-${value}'],
  },
  '/register/from-landing': {
    'page.view': ['auth', 'register-from-landing'],
  },
  '/register/from-landing-no-verification': {
    'page.view': ['auth', 'register-from-landing-no-verification'],
  },
  '/register/invalid-invitation': {
    'auth.registrationInvalidInvitation.buttonLabel': ['invalid-invitation', 'continue'],
  },
  '/register/code-verification': {
    'page.view': ['auth', 'code-verification'],
    'guests.emailVerify.sendCodeAgain': ['registration', 'email-code-verification-resend-code'],
    'guests.emailVerify.changeEmail': ['registration', 'email-code-verification-change-email'],
  },
  '/change-password/*': {
    'page.view': ['change-password', 'enter'],
  },
  '/reset-password': {
    'page.view': ['auth', 'reset-password'],
  },
  '/meliome/register': {
    'page.view': ['auth', 'vendor-registration'],
    '[AUTH] VENDOR_REGISTRATION_REQUEST': ['vendor-register', 'request'],
    '[AUTH] VENDOR_REGISTRATION_FAILURE': ['vendor-register', 'error', { error: 'error', email: 'payload.email' }],
    '[AUTH] VENDOR_REGISTRATION_VALIDATION_FAILURE': [
      'vendor-register',
      'validation-error',
      { validation: 'error.validationErrors' },
    ],
    '[AUTH] VENDOR_REGISTRATION_SUCCESS': ['vendor-register', 'password-continue-success'],
  },
  '/orgs/*/vendors': {
    'page.view': ['vendors', 'vendors-list'],
    'toggle-actions-menu': ['vendors-delivery-methods', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'view-vendors-actions': ['vendors', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  '/orgs/*/vendors/*/delivery-method/virtual/new': {
    ...mapApiSlice('[DELIVERYMETHODS] CREATE', 'virtual-delivery-method', 'create-delivery-method'),
    ...mapApiSlice('[DELIVERYMETHODS] UPDATE', 'virtual-delivery-method', 'update-delivery-method'),
    'go.exit': ['virtual-delivery-method', 'exit'],
  },
  '/orgs/*/vendors/*/delivery-method/create/bank-details': {
    'page.view': ['vendors', 'add-bank-delivery-method'],
    'go.exit': ['add-bank-delivery-method', 'exit'],
    VirtualCardMatchModal: [
      'vendors',
      'virtual-card-match-modal',
      {
        toggle: 'toggle',
      },
    ],
    'accept-virtual-card-match': ['virtual-card-match-modal', 'click-got-it', { vendorId: 'vendorId' }],
  },
  '/orgs/*/requests': {
    'page.view': ['requests', 'requests-list'],
    'toggle-actions-menu': ['requests', 'toggle-actions-menu', { isOpen: 'isOpen' }],
  },
  ...createPageViewEventsFromLocations('payor', melioMeLocations),
  '/meliome/pay/*': {
    'page.view': ['payor', 'payment-amount'],
  },
  '/meliome/pay/*/expedite': {
    'page.view': ['payor', 'expedite-payment'],
  },
  '/meliome/pay/*/payment-date': {
    'page.view': ['payor', 'payment-date'],
    'toast-notification': ['notification-${type}', 'select-deducted-month-expired'],
  },
  '/meliome/pay/*/success/signUp': {
    'page.view': ['payor', 'successSignUp'],
  },
  '/meliome/pay/*/t': {
    'page.view': ['payor', 'button-with-params'],
  },
  '/meliome/pay/*/edit-funding-source': {
    'page.view': ['payor', 'edit-funding-source'],
    ...amexGuestEventMapping,
    ...visaGuestEventMapping,
  },
  '/meliome/register/email-verification': {
    'page.view': ['payor', 'register-verify-email'],
  },
  '/meliome/guest': {
    'page.view': ['payor', 'guest-payment-redirect'],
  },
  '/quickbooks/entry/bills-list/*': {
    'page.view': ['qbo-entrypoint', 'dashboard'],
  },
  '/quickbooks/entry/create-bill/*': {
    'page.view': ['qbo-entrypoint', 'create-bill'],
  },
  [DEFAULT_MAPPING_NAME]: {
    'companies-switcher': ['companies-switcher', 'toggle', { isOpen: 'isOpen', location: 'location' }],
    'companies-switcher-top': ['companies-switcher-top', 'company-changed', { toOrgId: 'toOrgId' }],
    'companies-switcher-bottom': ['companies-switcher-bottom', 'company-changed', { toOrgId: 'toOrgId' }],
    'nav.accountSettings': ['side-menu', 'account-settings'],
    'companies-switcher-mobile': ['companies-switcher-mobile', 'toggle', { isOpen: 'isOpen' }],
    'melio-wrapper-loaded': ['melio-wrapper-loaded', 'success'],
    'history-pop': ['page', 'browser-go-back'],
    'page-left': ['page', 'browser-left-or-reloaded'],
  },
  '/orgs/*/bill/*': {
    'bills.form.paymentActivity.actions.cancel': ['bill', 'delete-single-payment'],
    'bills.form.paymentActivity.actions.close': ['bill', 'close-marked-as-paid'],
    'bills.form.paymentActivity.actions.markAsUnpaid': ['bill', 'mark-as-unpaid'],
    'payments.form.deleteDialog.cancelText': ['bill', 'delete-payment-canceled'],
  },
  ...authEventsMapping,
  ...contactsEventsMapping,
  ...companiesEventsMapping,
  ...onboardingEventsMapping,
  ...defineRelationshipEventsMapping,
  ...getPaidEventsMapping,
  ...getProEventsMapping,
  ...regularBatchPaymentsMapping,
  ...vendorsEventsMapping,
  ...vendorOnboardingEventsMapping,
  ...fundingSourceEventMapping,
  ...quickbookMultiOrgOnboardingEventsMapping,
  ...receivingMethodsEventMapping,
  ...accountingSoftwareSyncEventsMapping,
  ...teamEventMapping,
  ...emailNotificationsEventMapping,
  ...approvalWorkflowsEventMapping,
  ...onboardingAddVendorEventsMapping,
  ...msnPublicFormOnboardingEventMapping,
  ...msnClaimAccountEventsMapping,
  ...scannedInvoicesEventMapping,
  ...selfServeRefundEventMapping,
  ...financingEventMapping,
  ...msnSalesFormEventMapping,
  ...vendorUpgradePaymentEventMapping,
};

export default MAPPINGS;
