import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { FEES } from 'src/utils/consts';
import FundingSourceBox from './FundingSourceBox';
import { fundingSourcesContainer, paymentMethods } from './styles';

type Props = {
  isVendorAbsorbedFee: boolean;
  vendorHasCCPaymentsBlocked?: boolean;
  vendorHasDebitCardPaymentsBlocked?: boolean;
};

export const PayWithInfo = ({
  isVendorAbsorbedFee,
  vendorHasCCPaymentsBlocked,
  vendorHasDebitCardPaymentsBlocked,
}: Props) => {
  const fundingSourceTypes = [
    {
      label: 'guests.register.fundingSources.creditCard',
      fee: FEES.PAID,
      icon: 'icon-credit-card-icon',
      hidden: vendorHasCCPaymentsBlocked,
    },
    {
      label: 'guests.register.fundingSources.bankTransfer',
      fee: FEES.FREE,
      icon: 'icon-bank-transfer',
    },
    {
      label: 'guests.register.fundingSources.debitCard',
      fee: FEES.PAID,
      icon: 'icon-debit-card-icon',
      preventVendorAbsorbFee: true,
      hidden: vendorHasDebitCardPaymentsBlocked,
    },
  ].filter((fundingSource) => !fundingSource.hidden);

  return (
    <Box w="full">
      <Box __css={paymentMethods}>
        <MIFormattedText label="guests.register.payUsing" />
      </Box>
      <Box __css={fundingSourcesContainer} data-testid="guests.register.payUsing.list">
        {fundingSourceTypes.map(({ label, icon, fee, preventVendorAbsorbFee }) => (
          <FundingSourceBox
            key={label}
            label={label}
            icon={icon}
            fee={fee}
            isAbsorbedFee={!preventVendorAbsorbFee && isVendorAbsorbedFee}
          />
        ))}
      </Box>
    </Box>
  );
};
