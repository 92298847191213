import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

export const ErrorPage = () => (
  <Box w="calc(100% - 9rem)" h="full" flex="1" boxSizing="border-box" p={16}>
    <h2 data-testid="errorPageTitle">
      <MIFormattedText label="error.title" />
    </h2>
    <p>We are sorry — something has gone wrong.</p>
    <p>Our team has been notified.</p>
  </Box>
);

ErrorPage.defaultProps = {};
