import { useEffect } from 'react';
import { Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { RegistrationFlow } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { RegisterPage } from '../RegisterPage';
import { registrationLocations } from './locations';

export function AuthRegisterRouter() {
  const match = useRouteMatch();
  const [registrationFlow] = useLocationState<RegistrationFlow>('registrationFlow');

  const history = useHistory();
  const location = useLocation<{ redirectUrl: string }>();

  useEffect(() => {
    history.push({
      ...location,
      state: { ...location.state, redirectUrl: `${location.pathname}${location.search}` },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <SmartRoute path={registrationLocations.vendorRegister} exact>
        <RegisterPage registrationFlow={registrationFlow ?? RegistrationFlow.VENDOR} />
      </SmartRoute>
      <SmartRoute path={registrationLocations.spendRegister} exact>
        <RegisterPage registrationFlow={registrationFlow ?? RegistrationFlow.SPEND_MANAGEMENT} />
      </SmartRoute>
      <SmartRoute path={match.path} exact>
        <RegisterPage registrationFlow={registrationFlow ?? RegistrationFlow.REGULAR} />
      </SmartRoute>
    </Switch>
  );
}
