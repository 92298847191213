import styled from 'styled-components';
import { TextField } from 'src/core/ds/form/fields';
import { FormRow } from 'src/ui/form/FormElements';

type Props = {
  submit: any;
  achModel: any;
};

const NewAchDeliveryMethodForm = ({ submit, achModel }: Props) => (
  <FormContainer onSubmit={submit}>
    <RowContainer>
      <TextField
        label="vendors.addDeliveryMethodByLink.routingNumber"
        model={achModel.routingNumber}
        type="tel"
        size="sm"
        autoFocus
        isRequired
        data-testid="vendorRoutingNumber"
        data-private
      />

      <TextField
        label="vendors.addDeliveryMethodByLink.accountNumber"
        model={achModel.accountNumber}
        type="tel"
        size="sm"
        isRequired
        data-testid="vendorAccountNumber"
        data-private
      />
    </RowContainer>
    <button type="submit" hidden />
  </FormContainer>
);

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0rem 0 4rem;
`;

const RowContainer = styled(FormRow)`
  margin-top: 2rem;
  align-items: flex-start;
`;

export default NewAchDeliveryMethodForm;
