import { featureFlags } from '@melio/shared-web';
import tail from 'lodash/tail';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIEnhancedDropDown } from 'src/components/common/DropDown/MIEnhancedDropDown';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalContainer, ModalMessage } from 'src/components/common/ModalMessage';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Link } from 'src/core/ds/link';
import { UseModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useBreak } from 'src/hoc';
import { profileStore } from 'src/modules/profile/profile-store';
import { usersApi } from 'src/modules/users/api';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { SortedAmexIndustriesByCategory } from 'src/pages/bill/utils';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { FeatureFlags, NotificationVariant } from 'src/utils/consts';
import { AmexModalTitle } from '../components/AmexModalTitle';

type Props = {
  dismiss: () => void;
  onConfirm: () => void;
  orgId: number;
  vendorId: string;
  vendorName: string;
  industries: SortedAmexIndustriesByCategory;
  isDataLoading: boolean;
  analyticsProps: {
    billId?: string | null;
    paymentRequestId?: string | null;
    vendorId: string;
    partialBillId?: string | null;
    isBatch: boolean;
  };
};

const LEARN_MORE_LINK =
  'https://help.meliopayments.com/en/articles/5744350-a-change-in-how-you-pay-using-your-american-express-card';

export const AmexIndustryModal: React.FC<UseModal<Props>> = ({
  dismiss,
  onConfirm,
  orgId,
  vendorId,
  vendorName,
  industries,
  isDataLoading,
  analyticsProps,
}: Props) => {
  const categories = industries.sortedCategories;
  const categoriesOptions = useMemo(() => categories.map((category) => ({ label: category, value: category })), [
    categories,
  ]);
  const { isMobile } = useBreak();
  const currentUser = useSelector(profileStore.selectors.profile);
  const vendorActions = useStoreActions(vendorsStore);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<Record<string, any> | null>(null);
  const [subCategoryOptions, setSubCategoryOptions] = useState<{ label: string; value: string }[]>([]);
  const [shouldDisplayZendesk] = featureFlags.useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const url = shouldDisplayZendesk
    ? 'https://melio.zendesk.com/hc/en-us/articles/4415821314076-A-change-in-how-you-pay-using-your-American-Express-card'
    : LEARN_MORE_LINK;

  const properties = {
    ...analyticsProps,
    selectedCategory,
    selectedSubCategory,
  };

  useEffect(() => {
    usersApi.trackEvent(currentUser.id, 'analytics-mcc-restriction-on-scheduleBill', {
      table: analyticsProps.billId ? 'bill' : 'payment-request',
      id: analyticsProps.billId || analyticsProps.paymentRequestId || '',
      key: 'batch',
      value: analyticsProps.isBatch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subCategoriesOfSelectedCategory =
      industries.byCategory[selectedCategory]?.map((el) => ({ label: el.description, value: el.code })) || [];
    const updatedSubCategoryOptions = [{ label: '', value: 'placeholder' }, ...subCategoriesOfSelectedCategory];
    setSubCategoryOptions(updatedSubCategoryOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (subCategoryOptions[0]?.value === 'placeholder') {
      setSubCategoryOptions(tail(subCategoryOptions));
    }
  }, [subCategoryOptions]);

  const onSelectCategory = (result) => {
    const { value } = result;
    setValidationErrors(null);
    setSelectedCategory(value);
    setSelectedSubCategory('placeholder');
  };

  const onSelectSubCategory = (result) => {
    const { value } = result;
    setValidationErrors(null);
    setSelectedSubCategory(value);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);

    if (!selectedCategory) {
      setValidationErrors({
        category: 'bills.pay.fundingSource.amexIndustryModal.categoryDropdown.errorMessage',
      });
      analytics.trackAction('amex-industry-error', { errorType: 'category', ...properties });

      setIsSubmitting(false);

      return;
    }

    if (!selectedSubCategory) {
      setValidationErrors({
        subCategory: 'bills.pay.fundingSource.amexIndustryModal.subCategoryDropdown.errorMessage',
      });

      analytics.trackAction('amex-industry-error', { errorType: 'subCategory', ...properties });

      setIsSubmitting(false);

      return;
    }

    try {
      await vendorActions.updateVendorMccCode({ orgId, id: vendorId, mccCode: selectedSubCategory });
      analytics.trackAction('amex-industry-save', properties);
    } catch (error) {
      setIsSubmitting(false);
      analytics.trackAction('amex-industry-error', { errorType: 'serverError', ...properties });
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'bills.pay.fundingSource.amexIndustryModal.notification.failure',
      });

      return;
    }

    if (onConfirm) {
      await onConfirm();
    }

    setIsSubmitting(false);
    dismiss();
    pushNotification({
      type: NotificationVariant.SUCCESS,
      msg: 'bills.pay.fundingSource.amexIndustryModal.notification.success',
      textValues: {
        vendorName,
      },
    });
  };

  const onCloseClick = () => {
    analytics.trackAction('amex-industry-close', properties);
    dismiss();
  };

  const onChangePaymentMethodClicked = () => {
    analytics.trackAction('amex-industry-change-payment-method', properties);
    dismiss();
  };

  const onClickLearnMore = () => {
    analytics.trackAction('amex-industry-learn-more', properties);
  };

  const isLoading = isSubmitting || isDataLoading;

  return (
    <StyledModalMessage
      onCloseClick={onCloseClick}
      titleComponent={
        <AmexModalTitle
          testId="amex-industry-modal-title"
          titleLabel="bills.pay.fundingSource.amexIndustryModal.title"
          subtitleLabel="bills.pay.fundingSource.amexIndustryModal.description"
          vendorName={vendorName}
          learnMoreLink={
            <Link href={url} isExternal onClick={onClickLearnMore}>
              <MIFormattedText label="bills.pay.fundingSource.amexIndustryModal.learnMoreLink" />
            </Link>
          }
        />
      }
      contentComponent={
        <Flex direction="column">
          <Flex mb={10}>
            <MIEnhancedDropDown
              id="category"
              label="bills.pay.fundingSource.amexIndustryModal.categoryDropdown.label"
              placeholder="bills.pay.fundingSource.amexIndustryModal.categoryDropdown.placeholder"
              value={selectedCategory}
              onChange={onSelectCategory}
              options={categoriesOptions}
              errorMessage={validationErrors?.category}
              viewOnly={isLoading}
              disableSearch
              size="sm"
              required
            />
          </Flex>
          <Flex>
            <MIEnhancedDropDown
              id="subcategory"
              label="bills.pay.fundingSource.amexIndustryModal.subCategoryDropdown.label"
              placeholder="bills.pay.fundingSource.amexIndustryModal.subCategoryDropdown.placeholder"
              value={selectedSubCategory}
              onChange={onSelectSubCategory}
              options={subCategoryOptions}
              errorMessage={validationErrors?.subCategory}
              viewOnly={isLoading}
              hideOptions={!selectedCategory}
              disableSearch
              size="sm"
              required
              link={{
                label: isMobile
                  ? 'bills.pay.fundingSource.amexIndustryModal.bottomLinkComponent.mobileLabel'
                  : 'bills.pay.fundingSource.amexIndustryModal.bottomLinkComponent.label',
                action: onChangePaymentMethodClicked,
                linkLabel: 'bills.pay.fundingSource.amexIndustryModal.bottomLinkComponent.link',
              }}
            />
          </Flex>
        </Flex>
      }
      buttonComponent={
        <Button
          isFullWidth
          onClick={onSubmit}
          label="bills.pay.fundingSource.amexIndustryModal.cta"
          variant={ButtonVariants.primary}
          isLoading={isLoading}
        />
      }
    />
  );
};

const StyledModalMessage = styled(ModalMessage)`
  ${ModalContainer} {
    overflow-y: visible;
  }
`;
