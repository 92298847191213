import findLastKey from 'lodash/findLastKey';
import { FeatureFlags } from 'src/utils/featureFlags';

const platformPathMapping = {
  '/orgs/*/bills': {
    pattern: /^\/orgs\/\d+\/bills.*$/,
    platformPath: 'pay-dashboard/bills',
  },
  '/orgs/*/bills?campaign=financing': {
    pattern: /^\/orgs\/\d+\/bills\?.*campaign=financing.*$/,
    platformPath: 'financing/application?campaign=financing',
  },
  '/orgs/*/bills?status=scheduled': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=scheduled.*$/,
    platformPath: 'pay-dashboard/payments?status=scheduled',
  },
  '/orgs/*/bills?status=paid': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=paid.*$/,
    platformPath: 'pay-dashboard/payments?status=paid',
  },
  '/orgs/*/bills?status=unpaid&id=:billId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=unpaid.*&id=(?<billId>\d+)?.*$/,
    platformPath: 'pay-dashboard/bills/bill_:billId',
  },
  '/orgs/*/bills?status=unpaid&id=scanned-:scannedId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=unpaid.*&id=scanned-(?<scannedId>\d+)?.*$/,
    platformPath: 'pay-dashboard/bills/scn_:scannedId',
  },
  '/orgs/*/bills?status=unpaid&id=request-:paymentRequestId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=unpaid.*&id=request-(?<paymentRequestId>\d+)?.*$/,
    platformPath: 'pay-dashboard/bills/pymntreq_:paymentRequestId',
  },
  '/orgs/*/bills?status=unpaid&id=payment-:billId-:paymentId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=unpaid.*&id=payment-(?<billId>\d+)?-(?<paymentId>\d+)?.*$/,
    platformPath: 'pay-dashboard/payments/pymnt_:paymentId?status=failed',
  },
  '/orgs/*/bills?status=scheduled&id=payment-:billId-:paymentId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=scheduled.*&id=payment-(?<billId>\d+)?-(?<paymentId>\d+)?.*$/,
    platformPath: 'pay-dashboard/payments/pymnt_:paymentId?status=scheduled',
  },
  '/orgs/*/bills?status=paid&id=payment-:billId-:paymentId': {
    pattern: /^\/orgs\/\d+\/bills\?.*status=paid.*&id=payment-(?<billId>\d+)?-(?<paymentId>\d+)?.*$/,
    platformPath: 'pay-dashboard/payments/pymnt_:paymentId?status=paid',
  },
  '/orgs/*/contacts/vendors': {
    pattern: /^\/orgs\/\d+\/contacts\/vendors.*$/,
    platformPath: 'pay-dashboard/vendors',
  },
  '/orgs/*/contacts/vendors?id=:vendorId': {
    pattern: /^\/orgs\/\d+\/contacts\/vendors\?.*id=(?<vendorId>\d+)?.*$/,
    platformPath: 'pay-dashboard/vendors/vendor/vendor_:vendorId',
  },
  '/settings/:settingsPath': {
    pattern: /^\/settings\/(?<settingsPath>[^?]+).*$/,
    platformPath: 'settings/:settingsPath',
  },
  '/orgs/*/settings': {
    pattern: /^\/orgs\/\d+\/settings.*$/,
    platformPath: 'settings',
  },
  'orgs/*/partner/settings/:settingsSection': {
    pattern: /^\/orgs\/(\d+)\/([^/]+)\/settings\/(?<settingsSection>[^?]+).*$/,
    platformPath: 'settings/:settingsSection',
  },
  'orgs/*/settings/:settingsSection': {
    pattern: /^\/orgs\/\d+\/settings\/(?<settingsSection>[^?]+).*$/,
    platformPath: 'settings/:settingsSection',
  },
  '/orgs/*/settings/account': {
    pattern: /^\/orgs\/\d+\/settings\/account.*$/,
    platformPath: 'settings/profile',
  },
  '/orgs/*/settings/accounting-software': {
    pattern: /^\/orgs\/[^\\/]+\/settings\/accounting-software.*$/,
    platformPath: 'settings/accounting-software',
  },
  '/orgs/*/settings/users': {
    pattern: /^\/orgs\/\d+\/settings\/users.*$/,
    platformPath: 'settings/collaborators',
  },
  '/orgs/*/settings/email-notifications': {
    pattern: /^\/orgs\/\d+\/settings\/email-notifications.*$/,
    platformPath: 'settings/notification-preferences',
  },
  '/orgs/*/settings/billing-items': {
    pattern: /^\/orgs\/\d+\/settings\/billing-items.*$/,
    platformPath: 'settings/billing',
  },
  '/orgs/*/companies': {
    pattern: /^\/orgs\/\d+\/companies(\?.*)?$/,
    platformPath: 'accounts',
    accountingFirmPlatformPath: '/accountants/dashboard',
    accountingFirmSpecificFlag: FeatureFlags.PlatformAccountantsNewFlow,
  },
  '/orgs/*/batch-payments': {
    pattern: /^\/orgs\/\d+\/batch-payments.*$/,
    platformPath: 'pay-dashboard/bills',
  },
  '/orgs/*/just-pay': {
    pattern: /^\/orgs\/\d+\/just-pay.*$/,
    platformPath: 'schedule-payment/new?origin=onboarding',
  },
  'orgs/*/complete-details': {
    pattern: /^\/orgs\/\d+\/complete-details.*$/,
    platformPath: 'complete-details?redirect=get-paid/dashboard',
  },
  '/orgs/payment-request/:paymentRequestId/pay?payload=:payload': {
    pattern: /^\/orgs\/payment-request\/(?<paymentRequestId>.+)\/pay\?.*payload=(?<payload>[^&]+)?.*$/,
    platformPath: 'payment-request/:paymentRequestId/pay?payload=:payload',
  },
  '/orgs/*/companies/new/details': {
    pattern: /^\/orgs\/\d+\/companies\/new\/details.*$/,
    platformPath: '/accountants/client/creation-options',
    accountingFirmSpecificFlag: FeatureFlags.PlatformAccountantsNewFlow,
    isOnlyForAccountingFirm: true,
  },
  '/orgs/*/funding-sources/:fundingSourceId/micro-deposits/verify': {
    pattern: /^\/orgs\/\d+\/funding-sources\/(?<fundingSourceId>.+)\/micro-deposits\/verify.*$/,
    platformPath: 'settings/payment-methods/fsrc_:fundingSourceId/verify',
  },
  '/orgs/*/welcome/funding-sources/': {
    pattern: /^\/orgs\/\d+\/welcome\/funding-sources\/.*$/,
    platformPath: 'settings/payment-methods',
  },
};

export const getPlatformPath = (
  route: string,
  isAccountingFirm = false,
  featureFlagsConfig?: { [key: string]: boolean }
): null | string => {
  let newPath: string | null = null;

  const mostSpecificPath = findLastKey(platformPathMapping, ({ pattern }) => !!route.match(pattern));

  if (mostSpecificPath) {
    const {
      pattern,
      platformPath,
      accountingFirmPlatformPath,
      accountingFirmSpecificFlag,
      isOnlyForAccountingFirm,
    } = platformPathMapping[mostSpecificPath];
    const match = route.match(pattern);
    const customFeatureFlagValue = accountingFirmSpecificFlag && featureFlagsConfig?.[accountingFirmSpecificFlag];

    if (
      (isAccountingFirm && accountingFirmSpecificFlag && !customFeatureFlagValue) ||
      (isOnlyForAccountingFirm && !isAccountingFirm)
    ) {
      return null;
    }

    if (match) {
      const pathForCurrentUser =
        isAccountingFirm && accountingFirmPlatformPath ? accountingFirmPlatformPath : platformPath;
      newPath = pathForCurrentUser;

      if (match.groups) {
        newPath = Object.entries(match.groups).reduce(
          (acc, [groupName, groupValue]) => acc.replace(`:${groupName}`, groupValue),
          pathForCurrentUser
        );
      }
    }
  }

  if (!newPath) {
    return newPath;
  }

  const [newRoute, newQuery] = newPath.split('?');
  const newQueryParams = new URLSearchParams(newQuery);
  const existingQueryParams = new URLSearchParams(route.split('?')[1]);
  ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'].forEach((param) => {
    if (existingQueryParams.has(param)) {
      newQueryParams.set(param, existingQueryParams.get(param)!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }
  });

  return newQueryParams.toString() ? `${newRoute}?${newQueryParams}` : newPath;
};
