import { featureFlags } from '@melio/shared-web';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { getPlatformRedirectUrl } from 'src/modules/platform-app/utils';
import { FeatureFlags, OrgPreferencesTypeKeys } from 'src/utils/consts';

export const useRedirectToArPlatform = (orgId: number) => {
  const organizationPreferences = useOrganizationPreferences();
  const isArDashboardEnabledInPreferences = organizationPreferences?.[OrgPreferencesTypeKeys.isArEnabled] === 'true';
  const [isArDashboardFFEnabled] = featureFlags.useFeature(FeatureFlags.ARDashboard, false);

  if (isArDashboardFFEnabled || isArDashboardEnabledInPreferences) {
    window.location.replace(getPlatformRedirectUrl('ar/dashboard/invoices', orgId));

    return { isRedirected: true };
  }

  return { isRedirected: false };
};
