import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import NewDeliveryMethodForm from 'src/pages/vendor/components/NewDeliveryMethodForm';
import { ContentWrapper, Footer, Header } from 'src/pages/vendor/components/VendorLayoutElements';
import WhitePageAddressContainer from 'src/pages/vendor/components/WhitePageAddressContainer';
import { useEditCheckAddressFormState } from 'src/pages/vendor/edit-check-adress/hooks/useEditCheckAddressFormState';
import { usePaymentDataFromToken } from 'src/pages/vendor/hooks/usePaymentDataFromToken';
import { analytics } from 'src/services/analytics';
import { ModelView, useForm } from 'src/ui/form';
import { convertPaperCheck } from 'src/utils/address';
import { DeliveryType } from 'src/utils/consts';
import { formatCheckPrintName } from 'src/utils/delivery-methods';
import { CheckType } from 'src/utils/types';

type Props = {
  token: string;
  id: string;
};

const CheckChangeAddressPage = (props: Props) => {
  const { token, id } = props;
  const { paymentId, payment, isPaymentLoading, organization } = usePaymentDataFromToken({ token });
  const { filesUrls } = useStructuredSelectors(paymentsStore.selectors.payment(paymentId));
  const { actions, deliveryMethodModel, isDeliveryMethodProcessing } = useEditCheckAddressFormState({ token, id });

  const { onSubmit } = actions;
  const [deliveryMethodMV, deliveryMethodMVActions] = useForm<{
    paperCheck: CheckType;
  }>(deliveryMethodModel, {
    submit: (value) => {
      const { ...rest } = value;
      value.paperCheck.printName = formatCheckPrintName(value.paperCheck.printName);

      return onSubmit({ ...rest }, false);
    },
  });

  const paperCheck = useMemo<CheckType>(() => convertPaperCheck(deliveryMethodMV.paperCheck as ModelView<CheckType>), [
    deliveryMethodMV.paperCheck,
  ]);
  const { isAddressLoading, whitePageAddress } = useStructuredSelectors(
    deliveryMethodsStore.selectors.manualAddress(payment?.deliveryMethodId)
  );
  const [whitePageAddressView, setWhitePageAddressView] = useState(false);

  useEffect(() => {
    const suggestAddress =
      whitePageAddress &&
      !isEmpty(whitePageAddress) &&
      ((whitePageAddress.is_valid && whitePageAddress.diff) || !whitePageAddress.is_valid);
    setWhitePageAddressView(suggestAddress);

    if (suggestAddress)
      analytics.trackAction('suggest-alt-address', {
        flow: 'payment-schedule',
      });

    if (whitePageAddress && whitePageAddress.is_valid && !whitePageAddress.diff) {
      onSubmit({ deliveryType: DeliveryType.CHECK, paperCheck }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitePageAddress]);

  if (isPaymentLoading) return <AreaLoader />;

  if (whitePageAddressView) {
    return (
      <WhitePageAddressContainer
        setWhitePageAddressView={setWhitePageAddressView}
        isLoading={isDeliveryMethodProcessing || isAddressLoading}
        whitePageAddress={whitePageAddress}
        submit={onSubmit}
        model={deliveryMethodMV.paperCheck}
      />
    );
  }

  return (
    <OutsideLayout>
      <Header payment={payment} organization={organization} filesUrls={filesUrls} />
      <ContentWrapper title="vendors.editCheckAddress.checkNotSent.form.title">
        <NewDeliveryMethodForm
          submit={deliveryMethodMVActions.submit}
          selectedDeliveryMethod={DeliveryType.CHECK}
          checkModel={deliveryMethodMV.paperCheck}
        />
        <Button
          label="vendors.editCheckAddress.checkNotSent.form.saveCTA"
          onClick={deliveryMethodMVActions.submit}
          isLoading={isDeliveryMethodProcessing || isAddressLoading}
          size={ButtonSizes.lg}
          isFullWidth
        />
        <Footer companyName={organization?.companyName} />
      </ContentWrapper>
    </OutsideLayout>
  );
};

export default CheckChangeAddressPage;
