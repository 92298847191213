import { EventMappingsTreeChild } from 'src/services/analytics/types';

export const PAGE_EVENT = 'visa-industry-modal';

const baseProperties = {
  billId: 'billId',
  paymentRequestId: 'paymentRequestId',
  vendorId: 'vendorId',
  selectedCategory: 'selectedCategory',
  selectedSubCategory: 'selectedSubCategory',
  partialBillId: 'partialBillId',
  isBatch: 'isBatch',
};

const GUEST_MAPPING: EventMappingsTreeChild = {
  'visa-industry-modal': [
    PAGE_EVENT,
    'open',
    {
      ...baseProperties,
    },
  ],
  'visa-industry-save': [
    PAGE_EVENT,
    'click-save',
    {
      ...baseProperties,
    },
  ],
  'visa-industry-close': [
    PAGE_EVENT,
    'close',
    {
      ...baseProperties,
    },
  ],
  'visa-industry-error': [
    PAGE_EVENT,
    'error-not-selected',
    {
      errorType: 'errorType',
      ...baseProperties,
    },
  ],
  'visa-industry-change-payment-method': [
    PAGE_EVENT,
    'change-payment-method-link-clicked',
    {
      ...baseProperties,
    },
  ],
};

export { GUEST_MAPPING };
