import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import cardIcons from 'src/images/icons/card_icons.svg';
import { devices } from 'src/theme/appDevices';

type Props = {
  blockCCMoneyIn: boolean;
  blockDebitCardMoneyIn: boolean;
  promotionName?: string;
};

export const GuestPaymentInfo = ({ blockCCMoneyIn, promotionName }: Props) => {
  const shouldShowSection = !blockCCMoneyIn || promotionName;

  if (!shouldShowSection) {
    return null;
  }

  return (
    <PaymentInfoContainer>
      <PaymentMethodsContainer>
        {promotionName ? (
          <MIFormattedText
            label="guests.register.promotionDescription"
            values={{
              promotionName,
            }}
          />
        ) : (
          !blockCCMoneyIn && <MIFormattedText label="guests.register.trustedBy" />
        )}
      </PaymentMethodsContainer>
      {!blockCCMoneyIn && (
        <CardsIconContainer>
          <Image src={cardIcons} alt="Card icons" />
        </CardsIconContainer>
      )}
    </PaymentInfoContainer>
  );
};

const PaymentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  padding: 2.4rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  @media ${devices.mobile} {
    padding: 1.6rem;
  }
`;

const PaymentMethodsContainer = styled.div`
  line-height: 16px;
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  text-align: center;
`;

const CardsIconContainer = styled.div`
  margin-top: 1rem;

  @media ${devices.mobile} {
    margin-top: 1.4rem;
  }
`;

const Image = styled.img`
  height: 2.5rem;

  @media ${devices.mobile} {
    height: 2.8rem;
  }
`;
