import { useHistory } from 'react-router-dom';
import { OptionType } from 'src/components/common/DropDown/MIDropDown';
import { MIEnhancedDropDown } from 'src/components/common/DropDown/MIEnhancedDropDown';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { AmountCurrencyField, DateField, TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { MAX_CUSTOMERS_BATCH_UPLOAD, TEMP_CUSTOMER_ID } from 'src/pages/get-paid/create/constants';
import { ModelView } from 'src/ui/form';
import { FormButtons, FormContainer, FormRow, FormSubtitle } from 'src/ui/form/FormElements';
import { ACCEPT_FILES } from 'src/utils/consts';
import { useFileInput } from 'src/utils/useFileInput';

export type InvoiceDetailType = {
  customerId: number | null;
  totalAmount: string;
  customerName: string;
  invoiceNumber: string;
  dueDate: string;
};

export type InvoiceDetailsModelType = ModelView<InvoiceDetailType>;

type Props = {
  submit: () => Promise<void>;
  setSubmitType: (type) => void;
  submitType?: 'saveAndClose' | 'saveAndSent' | null;
  cancel: () => void;
  model: InvoiceDetailsModelType;
  onBatchCustomerUpload: (file: File) => void;
  createStatus?: boolean;
  contactOptions: OptionType[];
};
const InvoiceDetailsForm = ({
  submit,
  cancel,
  model,
  contactOptions,
  createStatus,
  onBatchCustomerUpload,
  setSubmitType,
  submitType,
}: Props) => {
  const history = useHistory();
  const handleOnCancel = () => {
    cancel();
    history.goBack();
  };

  const onCustomerChange = (result) => {
    const { value } = result;
    const customer = contactOptions.find((contact) => contact.value === value);

    if (customer) {
      model.customerId.onChange({ value: customer.value });
      model.customerName.onChange({ value: customer.label });
    } else if (value) {
      model.customerId.onChange({ value: TEMP_CUSTOMER_ID });
      model.customerName.onChange({ value });
    }
  };

  const batchCustomersUpload = useFileInput(
    (files: Array<File>) => {
      onBatchCustomerUpload(files[0]);
    },
    { accept: ACCEPT_FILES.TEXT_LISTS, multiple: false }
  );

  const onLoadBatchCustomers = contactOptions.length < MAX_CUSTOMERS_BATCH_UPLOAD ? batchCustomersUpload : undefined;

  return (
    <Box>
      <FormContainer onSubmit={submit}>
        <FormRow>
          <FormSubtitle data-testid="invoice-details-header">
            <MIFormattedText label="getPaid.new.create.form.title" />
          </FormSubtitle>
        </FormRow>
        <FormRow>
          <MIEnhancedDropDown
            id="customerId"
            label="getPaid.new.create.form.customerLabel"
            placeholder="getPaid.new.create.form.customerPlaceholder"
            noOptionsLabel="getPaid.new.create.form.customerNoOptions"
            createLabel="getPaid.new.create.form.customerAddInSelect"
            allowCustom
            value={model.customerId.value}
            errorMessage={model.customerId.error}
            onChange={onCustomerChange}
            options={contactOptions}
            buttonAction={onLoadBatchCustomers}
            buttonLabel="contacts.batch.uploadButton.customers"
            required
          />
        </FormRow>
        <FormRow mbZero>
          <PrivateDataContainer>
            <AmountCurrencyField
              label="getPaid.new.create.form.amountLabel"
              placeholder="getPaid.new.create.form.amountPlaceholder"
              model={model.totalAmount}
              decimalScale={2}
              fixedDecimalScale
              isRequired
            />
          </PrivateDataContainer>
        </FormRow>
        <FormRow mbZero>
          <Box>
            <PrivateDataContainer>
              <TextField
                label="getPaid.new.create.form.invoiceLabel"
                placeholder="getPaid.new.create.form.invoiceNumberPlaceholder"
                model={model.invoiceNumber}
                isRequired
              />
            </PrivateDataContainer>
          </Box>
          <Box>
            <DateField
              label="getPaid.new.create.form.dueDateLabel"
              placeholder="getPaid.new.create.form.dueDatePlaceholder"
              model={model.dueDate}
              id="dueDate"
              isRequired
              setTimeToMidday
            />
          </Box>
        </FormRow>
        <FormRow>
          <Flex as={FormButtons} direction="column">
            <Button
              type="submit"
              label="getPaid.new.create.form.submitLabel"
              variant={ButtonVariants.primary}
              isLoading={createStatus && submitType === 'saveAndSent'}
              onClick={() => setSubmitType('saveAndSent')}
            />
            <Button
              my={5}
              type="submit"
              label="getPaid.new.create.form.saveAndClose"
              variant={ButtonVariants.secondary}
              isLoading={createStatus && submitType === 'saveAndClose'}
              onClick={() => setSubmitType('saveAndClose')}
            />
            <Button onClick={handleOnCancel} label="general.cancel" variant={ButtonVariants.tertiaryNaked} />
          </Flex>
        </FormRow>
      </FormContainer>
    </Box>
  );
};

export default InvoiceDetailsForm;
