import qs from 'qs';
import { all, call, getContext, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { userPreferencesFactory } from 'src/context/records';
import { authApi } from 'src/modules/auth/api';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { filesApi } from 'src/modules/files/api';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { organizationsApi } from 'src/modules/organizations/api';
import { clearPlatformTokens } from 'src/modules/platform-app/utils';
import { userPreferencesApi } from 'src/modules/users/api';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { InitUserContextSagaPayloadType } from 'src/redux/user/types';
import { intercomService } from 'src/services/intercom';
import { TranslationObject } from 'src/services/translation-service';
import { Site } from 'src/sites/site';
import { VerifyFundingSourceMicroDepositsErrorCodes } from 'src/utils/consts';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { isQbdtOrganization } from 'src/utils/organizations';
import { CompanyInfoType } from 'src/utils/types';
import { MakeOptional } from 'src/utils/utility-types';
import { AccountRecord, companyInfoFactory } from '../../pages/settings/records';
import {
  checkAndInitUserAction,
  checkAndInitUserFinishAction,
  clearUserInfoAction,
  deleteDeliveryMethodAction,
  deleteDeliveryMethodFailedAction,
  deleteDeliveryMethodSuccessAction,
  deleteFundingSourceAction,
  deleteFundingSourceFailedAction,
  deleteFundingSourceSuccessAction,
  initIntercomUserAction,
  initUserSuccessAction,
  loadCompanyInfoAction,
  loadCompanyInfoFailedAction,
  loadCompanyInfoSuccessAction,
  loadCompanyLogoAction,
  loadCompanyLogoFailedAction,
  loadCompanyLogoSuccessAction,
  loadDeliveryMethodsAction,
  loadDeliveryMethodsFailedAction,
  loadDeliveryMethodsSuccessAction,
  loadFundingSourcesAction,
  loadFundingSourcesFailedAction,
  loadFundingSourcesSuccessAction,
  loadProfileAction,
  loadProfileFailedAction,
  loadProfileSuccessAction,
  removeFundingSourceLabelAction,
  removeFundingSourceLabelFailedAction,
  removeFundingSourceLabelSuccessAction,
  setOrganizationPreferencesAction,
  setProfileAction,
  setUserPreferencesAction,
  updateFundingSourceLabelAction,
  updateFundingSourceLabelFailedAction,
  updateFundingSourceLabelSuccessAction,
  updateUserPreferenceAction,
  updateUserPreferenceFailedAction,
  updateUserPreferenceSuccessAction,
  verifyFundingSourceAction,
  verifyFundingSourceFailedAction,
  verifyFundingSourceSuccessAction,
} from './actions';
import {
  CHECK_AND_INIT_USER,
  CLEAR_STATE,
  CLEAR_USER_INFO,
  CLEAR_USER_INFO_FINISH,
  DELETE_DELIVERY_METHOD,
  DELETE_FUNDING_SOURCE,
  INIT_INTERCOM_USER,
  INIT_USER,
  LOAD_COMPANY_INFO,
  LOAD_COMPANY_LOGO,
  LOAD_DELIVERY_METHODS,
  LOAD_FUNDING_SOURCES,
  LOAD_PROFILE,
  REMOVE_FUNDING_SOURCE_LABEL,
  UPDATE_FUNDING_SOURCE_LABEL,
  UPDATE_USER_PREFERENCE,
  VERIFY_FUNDING_SOURCE,
} from './actionTypes';
import { getCompanyInfo, getOrgId, getOwnedVendorId } from './selectors';

function* calculateBankAccountStatuses({ orgId, fundingSource }) {
  try {
    if (isManualBankAccountNotVerified(fundingSource)) {
      yield call(financialAccountsApi.getVerificationStatus, orgId, fundingSource.id, {});
      const bankAccount = {
        ...fundingSource.bankAccount,
        canVerify: true,
        isBlocked: false,
      };

      return { ...fundingSource, bankAccount };
    }

    return fundingSource;
  } catch (e: any) {
    if (e.code === VerifyFundingSourceMicroDepositsErrorCodes.NOT_FOUND) {
      const bankAccount = {
        ...fundingSource.bankAccount,
        canVerify: false,
        isBlocked: false,
      };

      return { ...fundingSource, bankAccount };
    }

    if (e.code === VerifyFundingSourceMicroDepositsErrorCodes.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS) {
      const bankAccount = {
        ...fundingSource.bankAccount,
        canVerify: false,
        isBlocked: true,
      };

      return { ...fundingSource, bankAccount };
    }

    return fundingSource;
  }
}

function* loadFundingSources({
  resolve = () => undefined,
  reject = () => undefined,
}: Partial<ReturnType<typeof loadFundingSourcesAction>>) {
  const orgId = yield select(getOrgId);
  try {
    const { fundingSources } = yield call(financialAccountsApi.getFundingSources, orgId, {
      includeDeleted: true,
    });
    const fundingSourcesWithBankAccount = yield all(
      fundingSources
        .map((fundingSource) => AccountRecord(fundingSource).toJS())
        .map((fundingSource) => calculateBankAccountStatuses({ orgId, fundingSource }))
    );

    yield put(loadFundingSourcesSuccessAction(fundingSourcesWithBankAccount));
    resolve();
  } catch (e: any) {
    yield put(loadFundingSourcesFailedAction());
    reject(e);
  }
}

function* loadProfile({ resolve = () => undefined, reject = () => undefined }: ReturnType<typeof loadProfileAction>) {
  try {
    const { user } = yield call(authApi.check);
    yield put(loadProfileSuccessAction(user));
    resolve();
  } catch (e: any) {
    yield put(loadProfileFailedAction());
    yield put(clearUserInfoAction());
    reject(e);
  }
}

function* deleteFundingSource({ id, resolve, reject }: ReturnType<typeof deleteFundingSourceAction>) {
  const orgId = yield select(getOrgId);

  try {
    yield call(financialAccountsApi.deleteFundingSource, orgId, id);
    yield put(deleteFundingSourceSuccessAction(id));
    resolve();
  } catch (e: any) {
    yield put(deleteFundingSourceFailedAction());
    reject(e);
  }
}

function* updateFundingSourceLabel({
  id,
  nickname,
  resolve,
  reject,
}: ReturnType<typeof updateFundingSourceLabelAction>) {
  const orgId = yield select(getOrgId);

  try {
    const res = yield call(financialAccountsApi.updateFundingSourceLabel, orgId, id, { nickname });
    yield put(updateFundingSourceLabelSuccessAction(res.fundingSource));
    yield put(loadFundingSourcesAction(resolve, reject));
    resolve();
  } catch (e: any) {
    yield put(updateFundingSourceLabelFailedAction());
    reject(e);
  }
}

function* removeFundingSourceLabel({ id, resolve, reject }: ReturnType<typeof removeFundingSourceLabelAction>) {
  const orgId = yield select(getOrgId);

  try {
    const res = yield call(financialAccountsApi.updateFundingSourceLabel, orgId, id, null);
    yield put(removeFundingSourceLabelSuccessAction(res.fundingSource));
    yield put(loadFundingSourcesAction(resolve, reject));
    resolve();
  } catch (e: any) {
    yield put(removeFundingSourceLabelFailedAction());
    reject(e);
  }
}

function* verifyFundingSource({
  id,
  token,
  amount1,
  amount2,
  resolve,
  reject,
}: ReturnType<typeof verifyFundingSourceAction>) {
  try {
    const orgId = yield select(getOrgId);
    const { verificationResult } = token
      ? yield call(financialAccountsApi.verifyMicroDepositsWithToken, {
          token,
          id,
          amount1,
          amount2,
        })
      : yield call(financialAccountsApi.verifyMicroDeposits, orgId, {
          id,
          amount1,
          amount2,
        });

    if (verificationResult) {
      yield put(verifyFundingSourceSuccessAction(id));
      resolve();
    } else {
      yield put(
        verifyFundingSourceFailedAction(id, VerifyFundingSourceMicroDepositsErrorCodes.ERR_VERIFY_MICRO_DEPOSITS)
      );
      reject({
        code: VerifyFundingSourceMicroDepositsErrorCodes.ERR_VERIFY_MICRO_DEPOSITS,
      });
    }
  } catch (e: any) {
    yield put(verifyFundingSourceFailedAction(id, e ? e.code : ''));
    reject(e);
  }
}

function* loadDeliveryMethods({
  resolve = () => undefined,
  reject = () => undefined,
}: Partial<ReturnType<typeof loadDeliveryMethodsAction>>) {
  try {
    const orgId = yield select(getOrgId);
    const ownedVendorId = yield select(getOwnedVendorId);
    const { deliveryMethods } = yield call(deliveryMethodsApi.getDeliveryMethods, orgId, ownedVendorId);
    const deliveryMethodsRecords = deliveryMethods.map((deliveryMethod) => deliveryMethodFactory(deliveryMethod));
    yield put(loadDeliveryMethodsSuccessAction(deliveryMethodsRecords));
    resolve();
  } catch (e: any) {
    yield put(loadDeliveryMethodsFailedAction());
    reject(e);
  }
}

function* deleteDeliveryMethod({ id, resolve, reject }: ReturnType<typeof deleteDeliveryMethodAction>) {
  try {
    const orgId = yield select(getOrgId);
    const ownedVendorId = yield select(getOwnedVendorId);
    yield call(deliveryMethodsApi.deleteDeliveryMethodById, orgId, ownedVendorId, id, {});
    yield put(deleteDeliveryMethodSuccessAction(id));
    resolve();
  } catch (e: any) {
    yield put(deleteDeliveryMethodFailedAction());
    reject(e);
  }
}

function* fetchCompanyLogo(fileId, orgId) {
  try {
    const { fileStorageUrl } = yield call(filesApi.fetchFileUrls, orgId, fileId);

    return fileStorageUrl || null;
  } catch (e: any) {
    return null;
  }
}

function* refreshCompanyLogo({ fileId }: Partial<ReturnType<typeof loadCompanyLogoAction>>) {
  try {
    const orgId = yield select(getOrgId);
    const fileStorageUrl = yield call(fetchCompanyLogo, fileId, orgId);
    yield put(loadCompanyLogoSuccessAction(fileStorageUrl, orgId));
  } catch (e: any) {
    yield put(loadCompanyLogoFailedAction());
  }
}

function* loadCompanyInfo({
  resolve = () => undefined,
  reject = () => undefined,
}: Partial<ReturnType<typeof loadCompanyInfoAction>>) {
  try {
    const orgId = yield select(getOrgId);
    const { localCompanyInfo, organizationPreferences, inboxEmailAddress } = yield call(
      organizationsApi.getCompanyInfo,
      orgId,
      true
    );
    const companyInfoRecord = companyInfoFactory({ ...localCompanyInfo, inboxEmailAddress });
    yield put(setOrganizationPreferencesAction(organizationPreferences));

    if (localCompanyInfo.logoId) {
      yield spawn(refreshCompanyLogo, { fileId: localCompanyInfo.logoId });
    }

    yield put(loadCompanyInfoSuccessAction(companyInfoRecord));
    resolve();
  } catch (e: any) {
    yield put(loadCompanyInfoFailedAction());
    reject(e);
  }
}

function* updateUserPreference({ id, value, resolve, reject }: ReturnType<typeof updateUserPreferenceAction>) {
  try {
    yield call(userPreferencesApi.updateUserPreference, id, value);
    yield put(updateUserPreferenceSuccessAction(id, value));
    resolve();
  } catch (e: any) {
    yield put(updateUserPreferenceFailedAction());
    reject(e);
  }
}

function* watchLoadCompanyLogo() {
  yield takeEvery(LOAD_COMPANY_LOGO, refreshCompanyLogo);
}

function* watchLoadCompanyInfo() {
  yield takeEvery(LOAD_COMPANY_INFO, loadCompanyInfo);
}

function* watchDeleteFundingSource() {
  yield takeEvery(DELETE_FUNDING_SOURCE, deleteFundingSource);
}

function* watchUpdateFundingSourceLabel() {
  yield takeEvery(UPDATE_FUNDING_SOURCE_LABEL, updateFundingSourceLabel);
}

function* watchRemoveFundingSourceLabel() {
  yield takeEvery(REMOVE_FUNDING_SOURCE_LABEL, removeFundingSourceLabel);
}

function* watchVerifyFundingSource() {
  yield takeEvery(VERIFY_FUNDING_SOURCE, verifyFundingSource);
}

function* watchLoadFundingSources() {
  yield takeEvery(LOAD_FUNDING_SOURCES, loadFundingSources);
}

function* watchLoadProfile() {
  yield takeEvery(LOAD_PROFILE, loadProfile);
}

function* watchLoadDeliveryMethods() {
  yield takeEvery(LOAD_DELIVERY_METHODS, loadDeliveryMethods);
}

function* watchDeleteDeliveryMethod() {
  yield takeEvery(DELETE_DELIVERY_METHOD, deleteDeliveryMethod);
}

function* watchUpdateUserPreferenceMethod() {
  yield takeEvery(UPDATE_USER_PREFERENCE, updateUserPreference);
}

function* initIntercomUser({ user, isLoggedInAs = false }: Partial<ReturnType<typeof initIntercomUserAction>>) {
  try {
    const site: Site = yield getContext('site');
    const orgId = user?.orgId;

    if (orgId) {
      const { localCompanyInfo } = yield call(organizationsApi.getCompanyInfo, orgId, true);

      intercomService.setChat({
        isHideDefault: true,
        isLoggedInAs,
        user,
        intercomSettings: site.intercomSettings,
        canContactSupport: localCompanyInfo.allowedActions.canContactSupport,
      });
    }
  } catch (e: unknown) {
    console.error('Intercom chat initialization failed:', e);
  }
}

function* initUserContext({ user, isLoggedInAs = false, resolve, reject }: InitUserContextSagaPayloadType) {
  try {
    yield all([
      put(setProfileAction(user, user?.organizations)),
      put(setUserPreferencesAction(userPreferencesFactory(user?.userPreferences ?? undefined))),
    ]);

    yield all([call(loadCompanyInfo, {}), call(loadFundingSources, {})]);
    const [ownedVendorId, companyInfo]: [ownedVendorId: number, companyInfo: CompanyInfoType] = yield all([
      select(getOwnedVendorId),
      select(getCompanyInfo),
    ]);

    yield initIntercomUser({ user, isLoggedInAs });

    const intl: TranslationObject | undefined = yield getContext('intl');

    if (intl?.setDefaultValue && companyInfo.billingSetting) {
      intl.setDefaultValue('fees_credit_value', companyInfo.billingSetting.fee.credit?.value);
      intl.setDefaultValue('fees_debit_value', companyInfo.billingSetting.fee.debit?.value);
    }

    if (ownedVendorId) {
      yield call(loadDeliveryMethods, {});
    }

    yield put(initUserSuccessAction(isLoggedInAs));
    resolve?.({ companyInfo });
  } catch (e: any) {
    yield put(clearUserInfoAction());
    reject && reject(e);
  }
}
function* clearUserContext() {
  yield put({
    type: CLEAR_STATE,
  });

  intercomService.clearChat();
  clearPlatformTokens();

  yield put({ type: CLEAR_USER_INFO_FINISH });
}

function getCurrentOrgInfo(checkResult, orgId) {
  const orgToSelect = orgId || checkResult.orgId;
  let org = checkResult.organizations.find((org) => org.id === orgToSelect);

  if (!org) {
    [org] = checkResult.organizations;
  }

  return {
    orgId: org.id,
    orgName: org.companyName,
  };
}

export function* checkAndInitUserInfo({
  orgId,
  resolve = () => undefined,
  reject,
}: MakeOptional<ReturnType<typeof checkAndInitUserAction>, 'type' | 'resolve' | 'reject'>) {
  try {
    const response = yield call(authApi.check);

    if (!response || !response.user) {
      yield put(clearUserInfoAction());
      reject?.();

      return;
    }

    let { user } = response;
    user.organizations = user.organizations.filter((o) => !isQbdtOrganization(o.createOrigin));

    const { pathname } = window.location;
    const billId = qs.parse(window.location.search, { ignoreQueryPrefix: true }).id;

    // redirect to orgs route to support old bill links
    if (pathname === '/bills' && billId) {
      const { organizationId } = yield call(organizationsApi.getOrgByBillId, billId);
      window.location.pathname = `orgs/${organizationId}${pathname}`;
    }

    user = {
      ...user,
      ...getCurrentOrgInfo(user, orgId),
    };

    sessionStorage.setItem('isSessionExpiredActiveTab', 'false');
    yield initUserContext({
      user,
      isLoggedInAs: false,
      resolve,
      reject,
    } as InitUserContextSagaPayloadType);
    yield put(checkAndInitUserFinishAction());
  } catch (e: any) {
    yield put(clearUserInfoAction());
    reject && reject(e);
  }
}

function* watchInitIntercomUser() {
  yield takeEvery(INIT_INTERCOM_USER, initIntercomUser);
}

function* watchInitUser() {
  yield takeEvery(INIT_USER, initUserContext);
}

function* watchCheckAndInitUser() {
  yield takeEvery(CHECK_AND_INIT_USER, checkAndInitUserInfo);
}

function* watchClearUserInfo() {
  yield takeEvery(CLEAR_USER_INFO, clearUserContext);
}

export default function* payBillFlowSagas() {
  yield all([
    watchLoadFundingSources(),
    watchLoadProfile(),
    watchDeleteFundingSource(),
    watchUpdateFundingSourceLabel(),
    watchRemoveFundingSourceLabel(),
    watchLoadDeliveryMethods(),
    watchLoadCompanyInfo(),
    watchLoadCompanyLogo(),
    watchDeleteDeliveryMethod(),
    watchUpdateUserPreferenceMethod(),
    watchInitIntercomUser(),
    watchInitUser(),
    watchCheckAndInitUser(),
    watchVerifyFundingSource(),
    watchClearUserInfo(),
  ]);
}
