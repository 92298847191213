import { useCallback, useRef, useState } from 'react';
import { Loader } from 'src/components/common/Loader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';
import { Input } from 'src/core/ds/input';
import { HStack } from 'src/core/ds/stack';
import Tooltip from 'src/core/ds/tooltip';
import { LocalFileProps } from 'src/pages/meliome/guest/hoc/withGuestData';
import { ACCEPT_FILES } from 'src/utils/consts';
import { baseBoxProps } from './styles';

type FileUploadProps = {
  onFileChange: (file?: File) => LocalFileProps;
  onFileClear(): void;
  file?: LocalFileProps;
};

export const FileUpload = ({ onFileChange, onFileClear, file }: FileUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isIdle, setIsIdle] = useState(!file?.fileName);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [fileData, setFileData] = useState<LocalFileProps | undefined>(file);
  const isFileUploaded = !isIdle && !isLoading && fileData?.fileName;

  const onSelectFile = useCallback(
    (e) => {
      const selectedFile = e?.currentTarget?.files?.[0];

      if (selectedFile) {
        setIsLoading(true);

        const fileUploadResult = onFileChange(selectedFile);
        setFileData(fileUploadResult);
        setHasError(Boolean(fileUploadResult?.error));
        setIsIdle(Boolean(fileUploadResult?.error));
        setIsLoading(false);
      }
    },
    [onFileChange]
  );

  const onOpenUploadFile = () => {
    fileInputRef?.current?.click?.();
  };

  const onRemoveFile = useCallback(() => {
    onFileClear();
    setIsIdle(true);
  }, [onFileClear]);

  return (
    <>
      <Input
        onChange={onSelectFile}
        type="file"
        id="upload-file-input"
        testId="upload-file-input"
        accept={ACCEPT_FILES.BILL_ATTACHMENT}
        key={fileData?.fileName}
        ref={fileInputRef}
        w={0}
        h={0}
        visibility="hidden"
      />

      {isIdle && (
        <HStack
          onClick={onOpenUploadFile}
          cursor="pointer"
          spacing={4}
          sx={baseBoxProps}
          mb={hasError ? 2 : baseBoxProps.mb}
          borderStyle="dashed"
        >
          <IconButton
            icon={<Icon name={IconNames.fileAdd} size={IconSize.m} color="black" />}
            testId="upload-file-icon"
            aria-label="upload-file"
          />
          <HStack spacing={2} w="full" alignItems="left" textStyle="body2" data-testid="upload-file-text-container">
            <Box>
              <MIFormattedText label="guests.register.uploadFile.idle" />
            </Box>
          </HStack>
        </HStack>
      )}
      {hasError && (
        <Box textAlign="left" mb={6} color="red.500" textStyle="body3">
          <MIFormattedText label="guests.register.uploadFile.error" />
        </Box>
      )}

      {isLoading && (
        <Flex sx={baseBoxProps} py={5} borderStyle="dashed">
          <Loader color="primary" context="page" />
        </Flex>
      )}

      {isFileUploaded && (
        <Flex sx={baseBoxProps} flexWrap="wrap" justifyContent="flex-end" alignItems="center">
          <Box>
            <Icon name={IconNames.bills} size={IconSize.m} testId="uploaded-file-icon" />
          </Box>
          <Box textAlign="left" ml={4} flexGrow={2}>
            <Box textStyle="body4Semi" color="grey.600">
              <MIFormattedText label="guests.register.uploadFile.fileHeader" />
            </Box>
            <Tooltip label={fileData?.fileName} placement="top">
              <Box textStyle="body2" width="fit-content" maxWidth="20rem" isTruncated data-testid="uploaded-file-name">
                {fileData?.fileName}
              </Box>
            </Tooltip>
          </Box>
          <HStack spacing={6} mt={4} mr={2}>
            <Box
              textStyle="body3Semi"
              color="red.600"
              cursor="pointer"
              aria-label="remove-file"
              role="button"
              onClick={onRemoveFile}
              _hover={{ color: 'red.700' }}
              data-testid="remove-file-button"
            >
              <MIFormattedText label="guests.register.uploadFile.remove" />
            </Box>
            <Box
              textStyle="body3Semi"
              aria-label="replace-file"
              role="button"
              cursor="pointer"
              _hover={{ color: 'primary.500' }}
              onClick={onOpenUploadFile}
              data-testid="replace-file-button"
            >
              <MIFormattedText label="guests.register.uploadFile.replace" />
            </Box>
          </HStack>
        </Flex>
      )}
    </>
  );
};
