import { differenceInMilliseconds } from 'date-fns';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import { InvoiceType } from 'src/modules/invoices/types';
import { PaymentsRequestStatus } from 'src/pages/get-pro/consts';
import { InvoiceStatus, PaymentRequestStatus, PaymentRequestTabs, PaymentStatus } from 'src/utils/consts';
import { getInvoiceStatusByPayment, getLatestPayment } from 'src/utils/payments';

type BillData = {
  extract: {
    dueDate?: string;
    totalAmount?: string;
    invoiceNumber?: string;
    customerMatchedName?: string;
    customerName?: string;
  };
};

export type BillDataResult = {
  dueDate?: string;
  totalAmount?: string;
  invoiceNumber?: string;
  customerNameValue?: string;
};

type PaymentRequestMappingOptions = {
  withDeliveryDate?: boolean;
};
const billToInvoiceStatus = (bill): InvoiceStatus => {
  const lastPayment = getLatestPayment(bill?.payments);

  if (lastPayment?.status === PaymentStatus.FAILED) {
    return InvoiceStatus.FAILED;
  }

  if (lastPayment?.status === PaymentStatus.COMPLETED) {
    return InvoiceStatus.PAID;
  }

  if (lastPayment?.status === PaymentStatus.IN_PROGRESS) {
    return InvoiceStatus.IN_PROGRESS;
  }

  return InvoiceStatus.SCHEDULED;
};

// TODO: add types to data
export const mapBillToInvoice = (data: any): InvoiceType => ({
  id: data?.id?.toString(),
  status: billToInvoiceStatus(data),
  totalAmount: data?.totalAmount?.toString(),
  invoiceNumber: data.invoiceNumber,
  dueDate: data?.paymentRequest?.dueDate || data?.dueDate,
  customer: {
    id: data?.paymentRequest?.contactId,
    companyName: data.organization?.companyName,
    contactName: data?.paymentRequest?.customerName || data.organization?.companyName,
    contactEmail: data?.paymentRequest?.customerEmail,
  },
  customerNote: data?.paymentRequest?.customerNote,
  files: data.files,
  link: data?.paymentRequest?.link,
  lastSeen: data?.paymentRequest?.lastSeen,
  createdAt: data?.paymentRequest?.createdAt,
  createOrigin: data?.paymentRequest?.createOrigin,
  payments: data.payments,
  bill: data,
  paymentRequest: data.paymentRequest,
  markedAsPaidAt: data.markedAsPaidAt,
  initiatedBy: data?.initiatedBy,
  delayReason: data?.delayReason,
  deliveryDate: data?.deliveryDate,
  updatedAt: data?.updatedAt,
});

export const mapPaymentToInvoice = (data: any): InvoiceType => ({
  id: data?.id?.toString(),
  status: getInvoiceStatusByPayment(data),
  totalAmount: data?.totalAmount?.toString(),
  invoiceNumber: data.invoiceNumber,
  dueDate: data?.paymentRequest?.dueDate,
  customer: {
    id: data?.contact?.id,
    companyName: data?.contact?.companyName || data.organization?.companyName,
    contactName: data?.contactName || data.organization?.companyName,
    contactEmail: data?.contact?.email || data?.paymentRequest?.customerEmail,
  },
  customerName: data?.customerName,
  customerNote: data?.paymentRequest?.customerNote,
  files: data.files,
  link: data?.paymentRequest?.link,
  lastSeen: data?.paymentRequest?.lastSeen,
  createdAt: data?.paymentRequest?.createdAt,
  createOrigin: data?.paymentRequest?.createOrigin,
  payments: data.payments,
  bill: data.bill,
  paymentRequest: data.paymentRequest,
  markedAsPaidAt: data.markedAsPaidAt,
  initiatedBy: data?.initiatedBy,
  deliveryDate: data?.deliveryDate,
  updatedAt: data?.updatedAt,
  delayReason: data?.delayReason,
  cancelledAt: data?.cancelledAt,
  isPending: data?.isPending,
  pendingReason: data?.pendingReason,
  isEligibleForCancel: data?.isEligibleForCancel,
});

const paymentRequestToInvoiceStatus = (paymentRequest): InvoiceStatus => {
  const lastPayment = getLatestPayment(paymentRequest?.bill?.payments);

  if (lastPayment?.status === PaymentStatus.FAILED) {
    return InvoiceStatus.FAILED;
  }

  if ([PaymentRequestStatus.CREATED, PaymentRequestStatus.FULFILLED].includes(paymentRequest.status)) {
    if (paymentRequest.markedAsPaidAt !== null) {
      return InvoiceStatus.MARKED_AS_PAID;
    }

    return InvoiceStatus.CREATED;
  }

  if (paymentRequest.status === PaymentRequestStatus.MARKED_AS_PAID) {
    return InvoiceStatus.MARKED_AS_PAID;
  }

  if (paymentRequest.status === PaymentRequestStatus.PENDING && paymentRequest.cancelledByCustomerAt) {
    return InvoiceStatus.CANCELLED_BY_CUSTOMER;
  }

  return InvoiceStatus.PENDING;
};
export const mapPaymentRequestToInvoice = (data: any, options?: PaymentRequestMappingOptions): InvoiceType => ({
  id: data?.id?.toString(),
  status: paymentRequestToInvoiceStatus(data),
  approvalStatus: data.approvalStatus,
  totalAmount: data?.totalAmount?.toString(),
  invoiceNumber: data.invoiceNumber,
  initiatedBy: data?.initiatedBy,
  dueDate: data.dueDate,
  customer: {
    id: data.contactId,
    contactName: data.customerName,
    contactEmail: data.customerEmail || data?.contact?.email,
    contactPhone: data.contactPhone || data?.contact?.phone,
    companyName: data?.contact?.companyName,
  },
  customerNote: data.customerNote,
  files: data.files,
  link: data.link,
  publicRef: data.publicRef,
  lastSeen: data.lastSeen,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  payments: data?.bill?.payments || [],
  bill: data.bill,
  markedAsPaidAt: data.markedAsPaidAt,
  isMelioUser: Boolean(data?.contact?.isMelioUser) || false,
  createOrigin: data?.createOrigin,
  ...(options?.withDeliveryDate ? { deliveryDate: data.deliveryDate } : {}),
  isEligibleForCancel: data?.isEligibleForCancel || false,
});

export const mapInvoice = (response) => {
  if (response.paymentRequest) {
    return mapPaymentRequestToInvoice(response.paymentRequest);
  }

  return mapBillToInvoice(response.bill);
};

export const mapPaymentRequestForGetProTable = (data) =>
  data.map((request) => {
    const isSeen = request.lastSeen;

    let status;

    if (request.counter > 1) {
      status = isSeen ? PaymentsRequestStatus.REMINDER_SEEN : PaymentsRequestStatus.REMINDER;
    } else {
      status = isSeen ? PaymentsRequestStatus.SENT_SEEN : PaymentsRequestStatus.SENT;
    }

    return {
      ...mapPaymentRequestToInvoice(request),
      status,
    };
  });

export const sortItems = (items, tab) => {
  if (tab === PaymentRequestTabs.SCHEDULED) {
    return items;
  }

  return items.sort((a, b) => {
    const dateA = a.markedAsPaidAt || getLatestPayment(a.payments)?.deliveryEta;
    const dateB = b.markedAsPaidAt || getLatestPayment(b.payments)?.deliveryEta;

    return differenceInMilliseconds(new Date(dateB), new Date(dateA));
  });
};

export const mapBillsAndInvoices = (items) =>
  map(items, (item) => {
    if (item.isInvoice) {
      return mapPaymentRequestToInvoice(item);
    }

    return mapBillToInvoice(item);
  });

export const mapPaymentAndInvoice = (item, paymentRequestMappingOptions?: PaymentRequestMappingOptions) => {
  if (item.isInvoice) {
    return mapPaymentRequestToInvoice(item, paymentRequestMappingOptions);
  }

  return mapPaymentToInvoice(item);
};

export const mapPaymentsAndInvoices = (items) =>
  map(items, (item) => mapPaymentAndInvoice(item, { withDeliveryDate: true }));

export const removeInvoicesFromList = (state, requestStatus, ids) => {
  forEach(state.lists, (list, listName) => {
    if (listName.includes(`"requestStatus":"${requestStatus}"`)) {
      list.order = list?.order?.filter((item) => ids.indexOf(item) === -1);
    }
  });
};

export const getExtractedBillData = (billData: BillData): BillDataResult => {
  if (!billData.extract) {
    return {};
  }

  const {
    extract: { dueDate, customerMatchedName, customerName, totalAmount, invoiceNumber },
  } = billData;

  const customerNameValue = customerMatchedName || customerName;
  const result: BillDataResult = {
    customerNameValue,
    dueDate: dueDate ? new Date(dueDate).toISOString() : '',
    totalAmount,
    invoiceNumber,
  };

  return pickBy(result);
};
