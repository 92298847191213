import { ReactNode } from 'react';
import Box from 'src/core/ds/box';
import { FormControlProps } from 'src/core/ds/form';
import { useBreak } from 'src/hoc';
import { ModelViewField } from 'src/ui/form';
import { DateFormats } from 'src/utils/date-fns';
import { isBusinessDay } from 'src/utils/dates';
import { DateFieldType } from 'src/utils/types';
import { DesktopDateField } from './components/DesktopDateField';
import { MobileDateField } from './components/MobileDateField';
import { getCalendarClassName, getContainerStyle, getDayClassName } from './utils';

type Props = {
  model?: ModelViewField<string>;
  id?: string;
  date?: Date | null;
  dueDate?: Date | null;
  min?: Date;
  max?: Date;
  openToDate?: Date;
  filterDate?: (value: Date) => boolean;
  renderDayContents?: (day: number, date: Date) => ReactNode;
  dateFormat?: DateFormats;
  placeholder?: string;
  label?: string;
  labelValues?: Record<string, unknown>;
  helperText?: string;
  helperTextValues?: Record<string, unknown>;
  errorMessage?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  isViewOnly?: boolean;
  size?: 'sm' | 'md';
  onChange?: (value: DateFieldType) => void | Promise<void>;
  inline?: boolean;
  withBottomElement?: boolean;
  overrideMobile?: boolean;
  testId?: string | null;
  onInputClick?: () => void;
  customInput?: ReactNode;
  onBlur?: () => void;
  onMonthChange?: (month: Date) => void;
  isClearable?: boolean;
  calendarContainerClass?: string;
  withIcon?: boolean;
  businessDaysOnly?: boolean;
  mb?: FormControlProps['mb'];
  setTimeToMidday?: boolean;
};

export const DateField = ({
  id,
  date,
  model,
  dateFormat = DateFormats.monthShortWithDateAndYear,
  placeholder = '',
  label,
  labelValues,
  helperText,
  helperTextValues,
  errorMessage = '',
  isDisabled = false,
  isRequired = false,
  isViewOnly = false,
  size = 'md',
  onChange,
  inline = false,
  min = undefined,
  max = undefined,
  filterDate = undefined,
  renderDayContents = undefined,
  overrideMobile = false,
  withBottomElement,
  openToDate = undefined,
  testId = null,
  dueDate,
  onInputClick,
  customInput,
  onBlur,
  onMonthChange,
  isClearable = true,
  businessDaysOnly,
  calendarContainerClass = undefined,
  withIcon,
  mb,
  setTimeToMidday = false,
}: Props) => {
  const device = useBreak();
  const isMobileDateField = (device.isMobile || device.isPhablet) && !overrideMobile;
  const inputId = id || model?.id;
  const inputValue = date || (model?.value ? new Date(model.value) : null);
  const inputTestId = testId || `input-${id}`;
  const isInputClearable = isClearable && !isViewOnly;
  const calendarClassName = getCalendarClassName(calendarContainerClass, withBottomElement, inline);
  const containerStyle = getContainerStyle(inline, isMobileDateField, customInput, size);

  const handleChange = (value: Date | null) => {
    let finalChangedValue = value;

    if (setTimeToMidday) {
      finalChangedValue = value ? new Date(value.setHours(12, 0, 0, 0)) : null;
    }

    onChange
      ? onChange({ id: inputId || '', date: finalChangedValue })
      : model?.onChange({ value: finalChangedValue?.toISOString() || '' });
  };

  const handleFilterDate = (day: Date) => {
    if (businessDaysOnly) {
      return isBusinessDay(day);
    }

    if (filterDate) {
      day.setHours(12);

      return filterDate(day);
    }

    return true;
  };

  const handleDayClassName = (inputValue: Date) => getDayClassName(inputValue, dueDate, calendarClassName);

  return (
    <Box sx={containerStyle} data-testid={`${inputTestId}-container`}>
      {isMobileDateField ? (
        <MobileDateField
          id={inputId}
          size={size}
          model={model}
          label={label}
          labelValues={labelValues}
          errorMessage={errorMessage}
          isRequired={isRequired}
          isDisabled={isDisabled}
          helperText={helperText}
          helperTextValues={helperTextValues}
          onChange={handleChange}
          date={inputValue}
          testId={`${inputTestId}-passthrough`}
          min={min}
          max={max}
          withIcon={withIcon}
          mb={mb}
        />
      ) : (
        <DesktopDateField
          id={inputId}
          label={label}
          labelValues={labelValues}
          model={model}
          size={size}
          errorMessage={errorMessage}
          helperText={helperText}
          helperTextValues={helperTextValues}
          date={inputValue}
          isRequired={isRequired}
          isViewOnly={isViewOnly}
          minDate={min}
          maxDate={max}
          withIcon={withIcon}
          customInput={customInput}
          onChange={handleChange}
          dateFormat={dateFormat}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isClearable={isInputClearable}
          inline={inline}
          calendarClassName={calendarClassName}
          filterDate={handleFilterDate}
          testId={inputTestId}
          onGetDayClassName={handleDayClassName}
          onInputClick={onInputClick}
          onBlur={onBlur}
          onMonthChange={onMonthChange}
          renderDayContents={renderDayContents}
          openToDate={openToDate}
          mb={mb}
        />
      )}
    </Box>
  );
};
