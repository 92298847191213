import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { SelectionCard } from 'src/core/components/cards/SelectionCard';
import { IconNames } from 'src/core/ds/icon';
import { Stack } from 'src/core/ds/stack';
import { withNavigator } from 'src/hoc';
import { useApi } from 'src/hoc/useApi';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { organizationsApi } from 'src/modules/organizations/api';
import organizationStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { ACCOUNTING_FIRM_NAICS_CODE } from 'src/pages/onboarding/company-info/consts';
import { CompanyInfoProps, withCompanyInfoUpdates } from 'src/pages/onboarding/company-info/hoc/withCompanyInfoUpdates';
import { setCompanyInfoAction, updateUserPreferenceAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { industryApi } from 'src/services/api/industry';
import { useForm } from 'src/ui/form';
import { CompanyType, OrganizationCreateOrigin, UserPreferencesKey } from 'src/utils/consts';
import { CompanyInfoType } from 'src/utils/types';

const roleOptions = [
  {
    id: CompanyType.SMB,
    label: 'onboarding.companyInfo.type.options.smb.title',
    icon: IconNames.shop,
    hint: 'onboarding.companyInfo.type.options.smb.hint',
  },
  {
    id: CompanyType.ACCOUNTING_FIRM,
    label: 'onboarding.companyInfo.type.options.accounting-firm.title',
    icon: IconNames.calculator,
    hint: 'onboarding.companyInfo.type.options.accounting-firm.hint',
  },
];

type Props = {
  nextStepURL: string;
} & CompanyInfoProps;

const CompanyTypePage = ({ navigateToCompanyOnboardingPage, nextStepURL }: Props) => {
  const dispatch = useDispatch();
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const isOrganizationPreferencesUpdating = useSelector(organizationStore.selectors.isOrganizationPreferencesUpdating);
  const companyInfo = useSelector(getCompanyInfo);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const { onApiCall: updateCompanyInfo, loading: isCompanyInfoUpdating } = useApi({
    api: organizationsApi.updateCompanyInfo,
  });
  const model = useMemo(() => ({ companyType: companyInfo.companyType }), [companyInfo]);
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();

  const submitAction = async (value: Partial<CompanyInfoType>) => {
    let allowCompaniesSwitcher = false;
    const isAccountingFirm = value.companyType === CompanyType.ACCOUNTING_FIRM;
    analytics.track('onboarding-company-info', 'submit-company-type', {
      isAccountingFirm,
      isGetPaid: !!ownedVendorId,
    });

    if (isAccountingFirm) {
      allowCompaniesSwitcher = true;
    }

    const requestsList: Promise<unknown>[] = [updateCompanyInfo(orgId, value)];

    if (isAccountingFirm)
      requestsList.push(industryApi.updateIndustry(orgId, { naicsCode: ACCOUNTING_FIRM_NAICS_CODE }));

    if (organizations.length === 1) {
      requestsList.push(
        new Promise((resolve, reject) =>
          dispatch(
            updateUserPreferenceAction(
              UserPreferencesKey.AllowCompaniesSwitcher,
              allowCompaniesSwitcher,
              resolve,
              reject
            )
          )
        )
      );
    }

    await Promise.all(requestsList);

    dispatch(setCompanyInfoAction({ ...companyInfo, ...value }));

    navigateToCompanyOnboardingPage(nextStepURL);
  };

  useEffect(() => {
    if (companyInfo.createOrigin === OrganizationCreateOrigin.ADD_COMPANY && companyInfo.companyType) {
      navigateToCompanyOnboardingPage(nextStepURL);
    }
  }, [companyInfo, navigateToCompanyOnboardingPage, nextStepURL]);

  const [companyTypeMV, { submit }] = useForm(model, {
    submit: submitAction,
  });

  const getProgressBarRelativeStep = () => (isUnifiedOnboardingOpen ? 1 / 13 : 1 / 7);

  const handleSelect = (companyType: string) => {
    companyTypeMV.companyType.onChange({ value: companyType as CompanyType });
  };

  return (
    <StepLayoutPage
      title="onboarding.companyInfo.type.title"
      subtitle="onboarding.companyInfo.type.subtitle"
      relativeStep={getProgressBarRelativeStep()}
      testId="company-type-page"
      onNext={submit}
      isNextDisabled={!companyTypeMV.companyType.value}
      isLoading={isOrganizationPreferencesUpdating || isCompanyInfoUpdating}
    >
      <Stack direction={{ base: 'column', md: 'row' }} align={{ base: 'unset', md: 'center' }} spacing={4}>
        {roleOptions.map(({ id, label, icon, hint }) => (
          <SelectionCard
            key={id}
            id={id}
            title={label}
            subTitle={hint}
            iconName={icon}
            onSelect={handleSelect}
            isSelected={companyTypeMV.companyType.value === id}
            analyticAction="select-category"
          />
        ))}
      </Stack>
    </StepLayoutPage>
  );
};

export default compose(withNavigator(), withCompanyInfoUpdates())(CompanyTypePage);
